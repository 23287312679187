import { FETCH_REGFORMDATA, FETCH_CHECKEMAIL, FETCH_PERSONALDETAILS, SEND_REG_USER_DATA, IS_LOGGEDIN, GET_AVATAR } from './type';
import axios from 'axios';
import qs from 'querystring';

export const fetchRegFormData = (regformdata) => dispatch => {
    // console.log('fetchRegFormData called')
    // console.log(regformdata)
    dispatch({
        type: FETCH_REGFORMDATA,
        payload: regformdata
    })
}

export const fetchCheckEmail = (email) => dispatch => {
    // console.log('fetchCheckEmail called')
    // console.log(email)
    const data = {
        email: email
    }
    const config = {
        "Content-Type": "application/json"
    }
    axios.post('https://resumion.com/apiv2/CheckEmail.php', qs.stringify(data), config)
        .then(res => res.data.success)
        .then(res => {
            dispatch({
                type: FETCH_CHECKEMAIL,
                payload: res
            })
        })
}

export const fetchPersonalDetails = () => dispatch => {
    // console.log('fetchPersonalDetails called')
    axios.get('/testapi/personaldetails.json')
        .then(res => res.data.personalDetails[0])
        // .then(res=> console.log(res.data.personalDetails[0]))
        .then(personalDetails => {
            console.log('userdetails')
            dispatch({
                type: FETCH_PERSONALDETAILS,
                payload: personalDetails
            })
        }
        )
}

export const isLoggedIn = () => dispatch => {
    // console.log('isLoggedIn called')
    if (localStorage.getItem("user") === null) {
        localStorage.setItem("user", JSON.stringify(['', '', '', null, '']))
        localStorage.setItem("avatar", JSON.stringify(['']))
        dispatch({
            type: IS_LOGGEDIN,
            payload: JSON.stringify(['', '', '', null])
        })
    }
    if (localStorage.getItem("user") !== null) {
        dispatch({
            type: IS_LOGGEDIN,
            payload: JSON.parse(localStorage.getItem("user"))
        })

        dispatch({
            type: GET_AVATAR,
            payload: JSON.parse(localStorage.getItem("avatar"))[0]
        })
        // console.log(localStorage.getItem("user"))
    }
}

// export const sendRegUserData = (regdata) => dispatch => {
//     // console.log('sendRegUserData called')
//     const data = {
//         first_name: regdata.first_name,
//         last_name: regdata.last_name,
//         email: regdata.email,
//         phone: regdata.phone,
//         address: regdata.address,
//         country: regdata.country,
//         city: regdata.city,
//         job_category: regdata.job_category,
//         templateid: regdata.templateid,
//         password: regdata.password,
//         terms: regdata.terms,
//         newsletters: regdata.newsletters,
//     }
//     const config = {
//         "Content-Type": "application/json"
//     }
//     // console.log(data)
//     axios.post('https://resumion.com/api/CreateAccount.php', qs.stringify(data), config)
//         .then(res => res.data)
//         // .then(res => console.log(res))
//         .then(res => {
//             // console.log(res)
//             dispatch({
//                 type: SEND_REG_USER_DATA,
//                 payload: res
//             })
//             localStorage.setItem("user", JSON.stringify([res.data[0].first_name, res.data[0].last_name, res.data[0].userid, res.success, res.data[0].title, 0]))
//             localStorage.setItem("avatar", JSON.stringify(['']))
//             localStorage.setItem("proR", JSON.stringify([res.data[0].resumeid]))
//             localStorage.setItem("proC", JSON.stringify([res.data[0].coverletterid]))
//             // Calling action creator inside another action creator
//             dispatch(isLoggedIn())
//         })
//         .catch(error => {
//             // console.log(error.response)
//         })

// }