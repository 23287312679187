import axios from 'axios';

export const getUseridFromSession = () => {
    let userid = JSON.parse(localStorage.getItem("user"))[2];
    return userid;
}

export const getUIDFromSession = () => {
    let uid = JSON.parse(localStorage.getItem("uid"))[0];
    return uid;
}

// Feature removed
export const getResumeidFromSession = () => {
    let resumeid = JSON.parse(localStorage.getItem("proR"))[0];
    return resumeid;
}

export const getCoverletteridFromSession = () => {
    let coverletterid = JSON.parse(localStorage.getItem("proC"))[0];
    return coverletterid;
}

export const getIsEmailVerifiedFromSession = () => {
    let isemailverified = JSON.parse(localStorage.getItem("user"))[5];
    return isemailverified;
}

export const updateAvatarInSession = (avatar) => {
    localStorage.setItem("avatar", JSON.stringify([avatar]))
}

export const getAvatarFromSession = () => {
    let avatar = JSON.parse(localStorage.getItem("avatar"))[0];
    return avatar;
}

export const removeLoaders = () => {
    function removeElement(element) {
        element && element.parentNode && element.parentNode.removeChild(element);
    }
    removeElement(document.getElementById('lds-ring'));
    removeElement(document.getElementById('loader-logo'));
}

// Updating user first_name and last_name in localStorage
export const updateUserCredentialsInSession = (first_name, last_name, title) => {
    const userid = JSON.parse(localStorage.getItem("user"))[2]
    const success = JSON.parse(localStorage.getItem("user"))[3]
    const isemailverified = JSON.parse(localStorage.getItem("user"))[5]
    localStorage.setItem("user", JSON.stringify([first_name, last_name, userid, success, title, isemailverified]))
}

export const getMonth = (month) => {
    switch(month) {
        case "1":
            return 'January';
        case "2":
            return 'February';
        case "3":
            return 'March';
        case "4":
            return 'April';
        case "5":
            return 'May';
        case "6":
            return 'June';
        case "7":
            return 'July';
        case "8":
            return 'August';
        case "9":
            return 'September';
        case "10":
            return 'October';
        case "11":
            return 'November';
        case "12":
            return 'December';
        default:
            return '';
    }
}
// If value is null then return '-'
export const isNull = (data) => {
    if (data === null || data === "") {
        return '-';
    } else {
        return data;
    }
}

// Check input type number
export const onlyNumber = (event) => {
    {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}
}
