import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from '.././CommonFunctions';
import BtnLoader from '../../BtnLoader';
import Footer from '.././Footer';
import axios from 'axios';
import qs from 'querystring';
import MetaTags from 'react-meta-tags';
import SupportFloating from '../../Button/SupportFloating';

import {
    IconPDFSolidBlack
} from '../../icon/index';

class MakePayment extends Component {
    state = { 
        userid: '',
        package: '',
        product: '',
    }
    componentDidMount() {
        removeLoaders();
        this.setState({
            ...this.state,
            userid: getUseridFromSession()
        })
    }

    
    
    render() { 
        // this.handleChange = this.handleChange.bind(this);
        console.log(this.state.userid)
        // console.log(this.props.product)

        // let paymentURL = `https://resumion.com/payment/makepayment.php?userid=1&package=1&product=${this.props.product}`
        let paymentURL = `https://phonerdam.com/payment/makepayment.php?userid=1&package=1&product=${this.props.product}`
        console.log(paymentURL)
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Payment - Resumion</title>
                        <meta property="og:title" content="Subscription - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2">
                            <iframe className='w-100 iframe-big border-0 mt-4 mb-5' src={paymentURL} title="description"></iframe>
                            {/* <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                    
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <SupportFloating />
            </React.Fragment>
        );
    }
}
 
export default MakePayment;