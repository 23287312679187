import { FETCH_VIEW_RESUME, UPDATE_VIEW_RESUME_ID, FETCH_VIEW_CL } from '../actions/type';

const initialState = {
    details_view: {},
    resumeid_details_view: '',
    details_view_cl: {},
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case FETCH_VIEW_RESUME:
            return {
                ...state,
                details_view: actions.payload
            }
        case FETCH_VIEW_CL:
                return {
                    ...state,
                    details_view_cl: actions.payload
                }
        case UPDATE_VIEW_RESUME_ID:
            return {
                ...state,
                resumeid_details_view: actions.payload
            }
        default:
            return state;
    }
}
