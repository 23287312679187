import React, { Component } from 'react';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import BtnLoader from '../../BtnLoader';
import EmptyState from '../../components/EmptyState'
import { IconHeart, IconTrash, IconPlusCircleWhite } from '../../icon/index';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class Interests extends Component {
    state = {
        interests: null,
        view: true,
        edit: false,
        add_new: false,

        resumeid: null,
        layout_loader: true,
        isDisabled: 'disabled',

        interest_name: '',
        interest_name_edit: null,
        interest_id_edit: null,
        btn_loader: false
    }


    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.interests === null && this.props.interests !== null) {
            this.setState({
                ...this.state,
                interests: this.props.interests
            })
            if (prevState.layout_loader === true) {
                this.setState({
                    ...this.state,
                    layout_loader: false
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }

    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMendatoryFields())
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMendatoryFieldsEdit())
    }
    checkMendatoryFields = () => {
        {
            this.state.interest_name.length !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )

        }
    }

    checkMendatoryFieldsEdit = () => {
        {
            this.state.interest_name_edit !== null && this.state.interest_name_edit.length !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )
        }
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    handleAdd = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true,
            level: 1
        }, () => this.endBtnLoader())
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false,
            interest_name: ''
        }, () => this.checkMendatoryFields())
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            interest_name: this.state.interest_name,
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddInterest.php', qs.stringify(data), config)
            .then(res => res.data.data[0].interest[0])
            // .then(res=>console.log(res))
            .then(res => this.setState({
                view: true,
                edit: false,
                add_new: false,
                interest_name: null,
                interests: [...this.state.interests, res]
            }), () => this.checkMendatoryFields())
            .then(() => this.notify())
            .then(() => this.endBtnLoader())
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();

        const data = {
            id: this.state.interest_id_edit,
            interest_name: this.state.interest_name_edit,
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetInterest.php', qs.stringify(data), config)
            .then(res => res.data.data[0].interest[0])
            // .then(res => console.log(res))
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.interests.map(interest => interest.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let interests = [...this.state.interests];
                let interest = { ...interests[indices] };
                interest = res;
                interests[indices] = interest;
                this.setState({
                    view: true,
                    edit: false,
                    interests: interests
                });
                this.notify();
                this.endBtnLoader();
            })
    }

    handleDelete = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'interest'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    // .then(res => console.log(res.data))
                    .then(() => this.notifyDelete())
                const interests = this.state.interests.filter(interest => interest.id !== id);
                this.setState({ interests: interests });
            }
        })
    }

    handleEdit = (id) => {
        // console.log(id)
        const interests = this.state.interests.filter(interest => interest.id === id);
        this.setState({
            view: false,
            edit: true,
            interest_name_edit: interests[0].interest_name,
            interest_id_edit: interests[0].id,
        }, () => this.checkMendatoryFieldsEdit())
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        // console.log(this.state)
        // console.log(this.props.interests)

        if (this.state.interests !== null) {
            var interests = this.state.interests.map(interest => {
                return (
                    <div className="col-md-6" key={interest.id}>
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <h6 className="m-0">{interest.interest_name}</h6>
                            </div>
                            <div className="flex-fill text-right">
                                <button onClick={this.handleEdit.bind(this, interest.id)} className="btn btn-link-edit p-0 mr-2"><img src={IconEdit} alt="edit" height="20"/></button>
                                <button onClick={this.handleDelete.bind(this, interest.id)} className="btn btn-link-delete p-0"><img src={IconTrashSolid} alt="delete" height="20"/></button>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return (
            <div className="card mb-4">
                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconHeart} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">Interests/ Hobbies</h5>
                                        <p className="text-muted mb-0">Add your interests related to your professional or personal life.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view &&
                                    <React.Fragment>
                                        <div className="row">
                                            {interests}
                                            {this.state.interests !== null && this.state.interests.length === 0 &&
                                                <div className="col text-center">
                                                    <EmptyState />
                                                    <button onClick={this.handleAdd} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                                </div>
                                            }
                                        </div>
                                        {this.state.interests !== null && this.state.interests.length !== 0 &&
                                            <button onClick={this.handleAdd} className="btn btn-primary mt-4"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="interest_name_edit">Interest  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="interest_name_edit" placeholder="e.g. Travelling" value={this.state.interest_name_edit} required />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="interest_name">Interest  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="interest_name" placeholder="e.g. Travelling" value={this.state.interest_name} />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(Interests);