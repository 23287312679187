import React, { Component } from 'react';
import BtnLoader from '../BtnLoader';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserLogin, callLoader, retryLogin, isLoggedIn, getAvatar, getProductList } from '../actions/CommonActions';
import { IconRefresh, IconUnlockCircle, IconUserTimesCircle } from '../icon/index';
import { removeLoaders } from './CommonFunctions';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import qs from 'querystring';
import MetaTags from 'react-meta-tags';

class Login extends Component {
    state = {
        email: '',
        password: '',
        reset_email: '',
        login_form: true,
        reset_form: false,
        reset_success: false,
        reset_failed: false,
        isDisabled: 'disabled',
        isDisabledResetEmail: 'disabled'
    }
    componentDidMount() {
        this.props.isLoggedIn();
        this.props.getProductList();
        removeLoaders();

        // const response = await this.props.getIsSubscribed();
        // console.log(response);
    }

    // componentDidMount() {
    //     this.props.getIsSubscribed();
    // }

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled',
            isDisabledResetEmail: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled',
            isDisabledResetEmail: ''
        })
    }

    checkResetEmail = () => {
        const resetEmailLength = this.state.reset_email.length;
        
        resetEmailLength !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }


    handleSubmit = (e) => {
        e.preventDefault();
        // this.startBtnLoader();
        this.props.callLoader();
        this.props.fetchUserLogin(this.state.email, this.state.password);
        this.props.getAvatar();
        this.setState({
            reset_form: false,
            // isDisabled: 'disabled'
        })
        // this.props.isAuthenticated();
    }
    handleReset = (e) => {
        e.preventDefault()
        this.startBtnLoader()
        const data = {
            useremail: this.state.reset_email,
            changeRequest: 1
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/api/ForgetPassword.php', qs.stringify(data), config)
            .then(res => res.data.success)
            .then(res => res === 1 ?
                this.setState({
                    reset_success: true,
                    reset_failed: false
                }) : res === 0 ? 
                this.setState({
                    reset_failed: true,
                    reset_success: false
                }) : null
            )
            .then(() => this.endBtnLoader())
        // console.log(this.state.email)
    }

    handleForgotPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            login_form: !prevState.login_form,
            reset_form: !prevState.reset_form,
        }))
    }
    handleBackToLogin = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            login_form: !prevState.login_form,
            reset_form: !prevState.reset_form,
            reset_success: false,
            reset_failed: false,
            email: '',
            password: '',
            reset_email: ''
        }));
    }
    handleRetry = (e) => {
        e.preventDefault();
        this.props.retryLogin()
    }
    checkEmailPassLength = () => {
        const emailLength = this.state.email.length;
        const passwordLength = this.state.password.length;

        emailLength !== 0 && passwordLength !== 0 ?
            this.setState({
                ...this.state,
                isDisabled: ''
            }) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
        // console.log(this.state.regFormState)
    }
    handleChangeEmail = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkResetEmail())
    }
    handleChangePassword = (e) => {
        this.setState({
            password: e.target.value
        }, () => this.checkEmailPassLength())
    }


    checkResetEmail = () => {
        const emailLength = this.state.reset_email.length;
        
        emailLength !== 0 ? (
            this.setState({
                ...this.state,
                isDisabledResetEmail: ''
            })
        ) : (
                this.setState({
                    ...this.state,
                    isDisabledResetEmail: 'disabled'
                })
            )
        
    }
    render() {
        // console.log(this.props.user_credentials)
        // console.log(this.state)

        const success = this.props.user_credentials.success
        // console.log(this.props.user_credentials)
        if (success === 1) {
            var { first_name, last_name, userid } = this.props.user_credentials.data
            // this.props.isAuthenticated();
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRetry = this.handleRetry.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleBackToLogin = this.handleBackToLogin.bind(this);

        const { email, reset_email, password, isDisabled } = this.state
        const { btn_loader, is_disabled } = this.props.btn_controller

        // if (success === 1) {
        //     return (
        //         <Redirect to='/' />
        //     )
        // }
        // console.log(JSON.parse(localStorage.getItem("user"))[3])

        return (
            <React.Fragment>
                <MetaTags>
                    <title>Login - Resumion</title>
                    <meta property="og:title" content="Login - Resumion" />
                    <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                </MetaTags>
                <div className="mb-5">
                    <div className="d-flex justify-content-center">
                        <div className="col-sm-6 col-lg-4">
                            <div className="bg-white p-5 mt-5 shadow-sm rounded-lg">

                                {success === null && this.state.login_form === true ? (
                                    <React.Fragment>
                                        <div className="d-flex flex-column">
                                            <img className="m-auto" src={IconUnlockCircle} alt="login" height="84" />
                                            <h5 className="text-center mt-3 mb-5">Resumion Login</h5>
                                        </div>
                                        <form onSubmit={this.handleSubmit}>
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="fName">Email Address</label>
                                                    <input onChange={this.handleChangeEmail.bind(this)} value={email} type="email" id="email" className="form-control" name="email" placeholder="name@email.com" required />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <input onChange={this.handleChangePassword.bind(this)} value={password} type="password" className="form-control" name="password" placeholder="xxxxxxxxx" required />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary w-100" disabled={is_disabled}>
                                                {btn_loader === '1' ? (<BtnLoader />) : (
                                                    <span>Login</span>
                                                )}
                                            </button>
                                            <button onClick={this.handleForgotPassword} type="button" className="btn btn-link text-dark w-100">
                                                Forgot password?
                                        </button>
                                        </form>
                                    </React.Fragment>
                                ) : null}

                                {this.state.reset_form &&
                                    <form onSubmit={this.handleReset}>
                                        <h5 className="mb-5">Reset password</h5>
                                        {this.state.reset_success &&
                                            <div className="alert alert-success" role="alert">
                                                Reset password link has been sent to your email address. Please check inbox.
                                            </div>
                                        }
                                        {this.state.reset_failed &&
                                            <div className="alert alert-danger" role="alert">
                                                Please double check you email address. This email address does not exist.
                                            </div>
                                        }
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="reset_email">Email Address</label>
                                                <input onChange={this.handleChangeEmail.bind(this)} value={reset_email} type="email" id="reset_email" className="form-control" name="reset_email" placeholder="name@email.com" required />
                                            </div>
                                        </div>
                                        <button className="btn btn-danger w-100" disabled={this.state.isDisabledResetEmail}>
                                            {this.state.btn_loader === true ? (<BtnLoader />) : (
                                                <span>Reset password</span>
                                            )}
                                        </button>
                                        <button onClick={this.handleBackToLogin} type="button" className="btn btn-link text-dark w-100">
                                            Back to Login
                                    </button>
                                    </form>
                                }


                                {
                                    success === 1 ? (
                                        <Redirect to='/dashboard' />
                                        // <div className="text-center">
                                        //     <img className="m-auto" src={IconUserCheckCircle} alt="success" height="84" />
                                        //     <h5 className="mt-3">Login Success</h5>
                                        //     <p>Welcome back, {first_name + " " + last_name}!</p>
                                        //     <NavLink className="btn btn-success" to='/dashboard'>
                                        //         <img className="mr-1" src={IconDashboard} alt="icon" /> Go to dashboard
                                        //     </NavLink>
                                        // </div>
                                    ) : success === 0 ? (
                                        <div className="text-center">
                                            <img className="m-auto" src={IconUserTimesCircle} alt="error" height="84" />
                                            <h5 className="mt-3">Login Failed</h5>
                                            <p>Please check your email address or password</p>
                                            <button onClick={this.handleRetry} type="button" className="btn btn-danger">
                                                <img className="mr-1" src={IconRefresh} alt="icon" /> Retry login
                                        </button>
                                        </div>
                                    ) : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    btn_controller: state.COM_REDU.btn_controller
})

export default connect(mapStateToProps, { fetchUserLogin, callLoader, retryLogin, isLoggedIn, getAvatar, getProductList })(Login);