import React, { Component } from 'react';
import { removeLoaders, getUIDFromSession } from '../CommonFunctions';
import { Redirect } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import ThumbnailLoader from '../../ThumbnaiListlLoader';
import { Link } from 'react-router-dom';
import BtnLoader from '../../BtnLoader';
import axios from 'axios';
import qs from 'querystring';
import history from '../../history';
import {
    IconAngleLeftWhite
} from '../../icon/index';

class CreateRC extends Component {
    state = {
        resumetitle: '',
        resume_template_id: '',
        resume_template_list: [],
        covertitle: '',
        coverletter_template_id: '',
        coverletter_template_list: [],
        uid: '',
        btn_loader: false,
        isDisabled: 'disabled',
        creation_type: '',
        success: ''
    }
    componentDidMount() {
        removeLoaders();
        // this.nameInput.focus();
        this.setState({ uid: getUIDFromSession() })
        let creation_type = this.props.location.hash.slice(1);
        this.setState({ creation_type })

        creation_type === 'resume' && (
            axios.get('https://resumion.com/apiv2/ResumeTemplates.json')
            .then(res => this.setState({
                resume_template_list: res.data.data
            }))
        )

        creation_type === 'coverletter' && (
            axios.get('https://resumion.com/apiv2/CoverletterTemplates.json')
            .then(res => this.setState({
                coverletter_template_list: res.data.data
            }))
        )
    }

    handleChangeResumeName = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsForResume())
    }

    handleChangeCoverletterName = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsForCoverletter())
    }

    checkMandatoryFieldsForResume = () => {
        const resumetitle = this.state.resumetitle.length;
        const resume_template_id = this.state.resume_template_id;

        resumetitle !== 0 && resume_template_id !== '' ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            })
        )   
    }

    checkMandatoryFieldsForCoverletter = () => {
        const covertitle = this.state.covertitle.length;
        const coverletter_template_id = this.state.coverletter_template_id;

        covertitle !== 0 && coverletter_template_id !== '' ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            })
        )   
    }

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();

        const dataResume = {
            uid: this.state.uid,
            resumetitle: this.state.resumetitle,
            templateid: this.state.resume_template_id,
        }
        const dataCoverletter = {
            uid: this.state.uid,
            covertitle: this.state.covertitle,
            templateid: this.state.coverletter_template_id,
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post(
            this.state.creation_type === 'resume' ? 'https://resumion.com/apiv2/CreateResume.php' : this.state.creation_type === 'coverletter' ? 'https://resumion.com/apiv2/AddCoverLetter.php' : null, 
            qs.stringify(this.state.creation_type === 'resume' ? dataResume : this.state.creation_type === 'coverletter' ? dataCoverletter : null), config)
        .then(res =>
            res.data.success === 1 && 
            this.setState({
                success: 1
            })
        )
    }

    handleTemplateSelection = (type, template_id) => {
        type === 'resume' && (
            this.setState({ resume_template_id: template_id }, () => this.checkMandatoryFieldsForResume())
        )
        type === 'coverletter' && (
            this.setState({ coverletter_template_id: template_id }, () => this.checkMandatoryFieldsForCoverletter())
        )
    }
    

    render() { 
        // this.handleTemplateSelection = this.handleChange.bind(this);
        // console.log(this.state)

        if (this.state.success === 1) {
            return (
                <Redirect to='/dashboard' />
            )
        }

        if (this.state.resume_template_list.length !== 0) {
            var resume_templates = this.state.resume_template_list.map(template => {
                return (
                    <div className='col-md-4' key={template.id}>
                        <button type='button' className={`template text-center p-0 border-0 bg-transparent w-100 ${template.id === this.state.resume_template_id && 'active'}`} onClick={()=>this.handleTemplateSelection('resume', template.id)}>
                            <img src={template.thumbnail} className="img-fluid mb-2 thumbnail" />
                            <h4 className='pb-0 title text-black-3'>{template.title}</h4>
                        </button>
                    </div>
                )
            })
        }

        if (this.state.coverletter_template_list.length !== 0) {
            var coverletter_templates = this.state.coverletter_template_list.map(template => {
                return (
                    <div className='col-md-4' key={template.id}>
                        <button type='button' className={`template text-center p-0 border-0 bg-transparent w-100 ${template.id === this.state.coverletter_template_id && 'active'}`} onClick={()=>this.handleTemplateSelection('coverletter', template.id)}>
                            <img src={template.thumbnail} className="img-fluid mb-2 thumbnail" alt="thumbnail" />
                            <h4 className='pb-0 title text-black-3'>{template.title}</h4>
                        </button>
                    </div>
                )
            })
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Create Resume - Resumion</title>
                        <meta property="og:title" content="Create Resume - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>

                <header className="preview-panel position-absolute w-100">
                    <div className="d-flex action-bar justify-content-between align-items-center bg-dark p-2 position-fixed w-100">
                        <Link to="/dashboard" className="btn text-white btn-back d-flex align-items-center"><img className="mr-3" src={IconAngleLeftWhite} alt="back" height="18"/>Back</Link>
                        <h5 className='text-white mb-0'>Create a new {this.state.creation_type === 'resume' && ('resume')}{this.state.creation_type === 'coverletter' && ('cover letter')} template</h5>
                        {this.state.creation_type === "resume" &&
                            <button className="btn btn-primary m-1" disabled={this.state.isDisabled} onClick={ this.handleSubmit }>
                                {this.state.btn_loader ? (<BtnLoader />) : <span>Add Resume</span>}
                            </button>
                        }
                        {this.state.creation_type === "coverletter" &&
                            <button className="btn btn-primary m-1" disabled={this.state.isDisabled} onClick={ this.handleSubmit }>
                                {this.state.btn_loader ? (<BtnLoader />) : <span>Add Coverletter</span>}
                            </button>
                        }
                    </div>
                </header>

                <div className="d-flex justify-content-center">
                    <div className="col-md-7">
                        <div className="card createrc bg-transparent border-0">
                            <div className="card-body pt-3 pb-5 p-0">
                            {this.state.creation_type === "resume" &&
                            <>
                            {this.state.resume_template_list.length !== 0 ? (
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="">Resume Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                        <input ref={(input) => { this.nameInput = input; }} onChange={this.handleChangeResumeName} type="text" className="form-control" name="resumetitle" placeholder="Ex: Marketing manager" value={this.state.resumetitle} />
                                    </div>
                                    <div className="form-group">
                                        <h5 className="mt-4 mb-4 text-center">Select resume template</h5>
                                        <div className='row template-list'>
                                            {resume_templates}
                                        </div>
                                    </div>
                                    
                                </form>)
                                : (
                                    <ThumbnailLoader />
                                )}
                                </>
                                }
                                {this.state.creation_type === "coverletter" &&
                                    <>
                                    {this.state.coverletter_template_list.length !== 0 ? (
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="">Cover letter Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input ref={(input) => { this.nameInput = input; }} onChange={this.handleChangeCoverletterName} type="text" className="form-control" name="covertitle" placeholder="Ex: Marketing manager" value={this.state.covertitle} />
                                            </div>
                                            <div className="form-group">
                                                <h5 className="mt-4 mb-4 text-center">Select coverletter template</h5>
                                                <div className='row template-list'>
                                                    {coverletter_templates}
                                                </div>
                                            </div>
                                            
                                        </form>)
                                    : (
                                        <ThumbnailLoader />
                                    )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default CreateRC;