import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from './CommonFunctions';
import { Link, Redirect } from "react-router-dom";
import BtnLoader from '../BtnLoader';
import Footer from './Footer';
import axios from 'axios';
import qs from 'querystring';
import MetaTags from 'react-meta-tags';
import SupportFloating from '../Button/SupportFloating';
import { isLoggedIn } from '../actions/CommonActions';
import { connect } from 'react-redux';

import {
    IconPDFSolidBlack
} from '../icon/index';

class Subscription extends Component {
    state = { 
        userid: ''
    }
    componentDidMount() {
        removeLoaders();
        this.setState({
            ...this.state,
            userid: getUseridFromSession()
        })
    }
    
    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(this.state)

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }
        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Subscription - Resumion</title>
                        <meta property="og:title" content="Subscription - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="page-title text-center mt-5 mb-4">
                    <h3>Subscription</h3>
                    <p className="text-secondary">
                        Manage and monitor your subscription
                    </p>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2">
                            <div className='row d-flex align-items-stretch mb-4'>
                                <div className='col-8'>
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div>
                                                <h4 className='m-0 mb-1'>7 days trail subscription</h4>
                                                <p>24 Jan 2022 - 31 Jan 2022</p>
                                                <button className='btn btn-primary btn-sm'>Upgrade Package</button>
                                            </div>
                                            <div className='d-flex justify-content-start'>
                                                <h2>$2.99</h2>
                                                <p>/weekly</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="card">
                                        <div className="card-body">
                                            <p className='m-0 mb-1'>Next payment</p>
                                            <h4 className='m-0 mb-3'>On 13 Apr 2022</h4>
                                            <button className='btn btn-outline-primary btn-sm'>Renew Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <h5>Invoice (5)</h5>
                                    <div className='card mb-2'>
                                        <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                            <div className='d-flex justify-content-start'>
                                                <img src={IconPDFSolidBlack} alt="pdf" />
                                                <a href='#' className='m-0 invoice-number'>#20220405XWPROP</a>
                                            </div>
                                            <div>
                                                <p className='m-0 invoice-date'>
                                                    Date of Invoice: 
                                                    <span className='date'>14 Mar 2022</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mb-2'>
                                        <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                            <div className='d-flex justify-content-start'>
                                                <img src={IconPDFSolidBlack} alt="pdf" />
                                                <a href='#' className='m-0 invoice-number'>#20220405XWPROP</a>
                                            </div>
                                            <div>
                                                <p className='m-0 invoice-date'>
                                                    Date of Invoice: 
                                                    <span className='date'>14 Mar 2022</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mb-2'>
                                        <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                            <div className='d-flex justify-content-start'>
                                                <img src={IconPDFSolidBlack} alt="pdf" />
                                                <a href='#' className='m-0 invoice-number'>#20220405XWPROP</a>
                                            </div>
                                            <div>
                                                <p className='m-0 invoice-date'>
                                                    Date of Invoice: 
                                                    <span className='date'>14 Mar 2022</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mb-2'>
                                        <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                            <div className='d-flex justify-content-start'>
                                                <img src={IconPDFSolidBlack} alt="pdf" />
                                                <a href='#' className='m-0 invoice-number'>#20220405XWPROP</a>
                                            </div>
                                            <div>
                                                <p className='m-0 invoice-date'>
                                                    Date of Invoice: 
                                                    <span className='date'>14 Mar 2022</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mb-2'>
                                        <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                            <div className='d-flex justify-content-start'>
                                                <img src={IconPDFSolidBlack} alt="pdf" />
                                                <a href='#' className='m-0 invoice-number'>#20220405XWPROP</a>
                                            </div>
                                            <div>
                                                <p className='m-0 invoice-date'>
                                                    Date of Invoice: 
                                                    <span className='date'>14 Mar 2022</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card mb-2'>
                                        <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                            <div className='d-flex justify-content-start'>
                                                <img src={IconPDFSolidBlack} alt="pdf" />
                                                <a href='#' className='m-0 invoice-number'>#20220405XWPROP</a>
                                            </div>
                                            <div>
                                                <p className='m-0 invoice-date'>
                                                    Date of Invoice: 
                                                    <span className='date'>14 Mar 2022</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SupportFloating />
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials
})


// export default Subscription;
export default connect(mapStateToProps, { isLoggedIn })(Subscription);
