import React, { Component } from 'react';
import { removeLoaders } from './CommonFunctions';
import axios from 'axios';
import LogoWhite from '../logo-white.svg';
import { 
    IconLockCircle, 
    IconDownloadWhite,
    IconEnvelopeWhite,
    IconFacebookWhite,
    IconTwitterWhite,
    IconLinkedinWhite} from '../icon/index';
import ReactTooltip from 'react-tooltip';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from 'react-share';
import MetaTags from 'react-meta-tags';
import { Document, Page } from 'react-pdf';

class ResumeShare extends Component {
    state = {
        resume_data: '',
        share_status: '',
        share_url: '',
        pageNumber: 1,
        numPages: null,
    }
    componentDidMount() {
        removeLoaders();
        const resumeid = this.props.match.params.resumeid;
        axios.get('https://resumion.com/apiv2/GetUserPublicLink.php?resumeid=' + resumeid + '&templateid=1')
        .then(res => res.data.active)
        .then(res => {
            this.setState({
                share_status: res,
                share_url: "https://resumion.com/share/"+resumeid
            })
        })
    }

    componentDidUpdate() {   
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            pageNumber: numPages
        })
    }

    render() {
        // console.log(this.state)
        // console.log(this.props.match.params.userid)
        // console.log(this.props.location.hash)
        const resumeid = this.props.location.hash.slice(1);
        let URL = "https://resumion.com/apiv2/DownloadResume.php?resumeid="+resumeid+"&templateid=1&actiontype=preview"

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Share Your Resume - Resumion</title>
                        <meta property="og:title" content="Share Your Resume - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>

                <div className="full-screen resume-preview position-absolute w-100 d-flex align-items-stretch">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-center w-918">
                            {this.state.share_status === "1" && 
                                <React.Fragment>
                                    <div className="d-flex action-panel justify-content-between align-items-center bg-dark p-2 position-fixed w-100">
                                        <a href="/" rel="noopener noreferrer"><img className="logo-sm" src={LogoWhite} alt="Resumion" /></a>
                                        <div className="action-btn-group">
                                            <React.Fragment>
                                                <ReactTooltip/>
                                                <div className="social-btn-group">
                                                    <EmailShareButton url={this.state.share_url} children={
                                                    <button className="btn btn-danger p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Email" data-type="light">
                                                        <img src={IconEnvelopeWhite} alt="Email" width="24"/>
                                                    </button>
                                                    } />
                                                    <FacebookShareButton url={this.state.share_url} children={
                                                        <button className="btn btn-facebook p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Share on Facebook" data-type="light">
                                                            <img src={IconFacebookWhite} alt="Share on Facebook" height="24"/>
                                                        </button>
                                                    } />
                                                    <TwitterShareButton url={this.state.share_url} children={
                                                    <button className="btn btn-twitter p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Share on Twitter" data-type="light">
                                                        <img src={IconTwitterWhite} alt="Share on Twitter" width="24"/>
                                                    </button>
                                                    } />
                                                    <LinkedinShareButton url={this.state.share_url} children={
                                                    <button className="btn btn-linkedin p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Share on LinkedIn" data-type="light">
                                                        <img src={IconLinkedinWhite} alt="Share on LinkedIn" height="24"/>
                                                    </button>
                                                    } />
                                                    <a rel="noopener noreferrer" target="_blank" href={"https://resumion.com/apiv2/DownloadResume.php?resumeid="+resumeid+"&templateid=1&actiontype=download"} className="btn btn-success p-0 rounded-circle m-1" data-place="bottom" data-effect="solid" data-tip="Download" data-type="light">
                                                        <img src={IconDownloadWhite} alt="download" height="24"/>
                                                    </a>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </div>
                                    <div className="preview-container mb-5 rounded-lg">
                                        {this.props.match.params.resumeid !== '' &&
                                        <Document 
                                            loading="Loading resume..."
                                            renderMode="svg"
                                            file={URL}
                                            onLoadSuccess={this.onDocumentLoadSuccess}>
                                            {Array.from(
                                                new Array(this.state.pageNumber),
                                                (el, index) => (
                                                <Page
                                                    className="d-flex justify-content-center mb-4"
                                                    scale={1.3}
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    renderTextLayer={false}
                                                />
                                                ),
                                            )}
                                        </Document>
                                        }
                                    </div>
                                </React.Fragment>
                            }
                            {this.state.share_status === "0" &&
                            <React.Fragment>
                                <div className="col-sm-6 col-lg-6 text-center">
                                    <a target="_blank" href="/" rel="noopener noreferrer"><img className="logo-sm mt-4" src={LogoWhite} alt="Resumion" /></a>
                                    <div className="bg-white p-5 mt-5 shadow-sm rounded-lg">
                                        <img className="mb-3" src={IconLockCircle} alt="lock" height="64"/>
                                        <h6>Access denied!</h6>
                                        <small className="d-block text-muted mb-3">Sorry, the owner has made this resume private.</small>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    // user_credentials: state.COM_REDU.user_credentials
})

// export default connect(mapStateToProps, { isLoggedIn })(ResumeShare);
export default ResumeShare;