import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Builder from '../img/builder.svg';
import { removeLoaders } from './CommonFunctions';
import {
    IconFileTextCircleWhite,
    IconUsersCircleWhite,
    IconFilesCircleWhite,
    IconMagicCircle,
    IconCogCircle,
    IconFilesCircle,
    IconHeartCircle,
    IconUserPlusCircleYellow,
    IconSigninCircleYellow,
    IconFilePhotoCircleYellow,
} from '../icon/index';
import Footer from './Footer';
import SupportFloating from './../Button/SupportFloating';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { isLoggedIn } from '../actions/CommonActions';

class Home extends Component {
    state = {
        stats_build: '',
        stats_members: '',
        stats_templates: ''
    }
    componentDidMount() {
        removeLoaders();
        axios.get('https://resumion.com/api/HomeAccountStat.php')
        .then(res => res.data)
        .then(res => {
            // console.log(res)
            this.setState({
                stats_build: res.totalresumebuilt,
                stats_members: res.totalregistration,
                stats_templates: res.templates
            })
        })
        this.props.isLoggedIn()

        // console.log(this.props.location.hash);

        const referral = this.props.location.hash.slice(1);

        this.props.location.hash !== '' &&
        axios.get('https://resumion.com/api/UserReferralMediaLog.php?media='+referral)
        // .then(res => {
        //     console.log(res)
        // })
    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            appendDots: dots => (
                <div
                    style={{
                        borderRadius: "4px",
                        padding: "4px"
                    }}
                >
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: i => (
                <div
                    style={{
                        width: "48px",
                        height: "48px",
                        color: "#17223B",
                        border: "2px solid #17223B"
                    }}
                >
                    {i + 1}
                </div>
            )
        };

        return (
            <React.Fragment>
                <MetaTags>
                    <title>Free Online Resume Builder - Resumion</title>
                    <meta name="description" content="Create your resume just in 5 mins with free online resume builder. Let's create, download, share, print different types of stylish resume templates." />
                    <meta property="og:title" content="Free Online Resume Builder - Resumion" />
                </MetaTags>
                <div className="bg-white home-slider">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-sm-8 text-center">
                                <h4 className="text-uppercase mb-3 d-block">Free online resume builder</h4>
                                <h2>Create your resume just in 5 mins</h2>
                                <p className="">
                                Update your information and view, download, share, print different types of stylish resume templates which can be a deal breaker for you. Easy to use and done within minutes. Try it now for free.
                                </p>
                                {console.log(this.props.user_credentials)}
                                {this.props.user_credentials.success === 1 ? (
                                <Link className="btn btn-primary btn-lg btn-lg mb-5" to='/dashboard'>
                                    Edit your resume
                                </Link>) : (
                                <Link className="btn btn-primary btn-lg mb-5" to='/builder'>
                                    Create resume now
                                </Link>
                                )
                                }
                            </div>
                            <div className="">
                                <img className="m-auto img-fluid promo-graphic" src={Builder} alt="builder" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stats bg-primary text-center">
                    <div className="container">
                        <div className="d-flex justify-content-center p-5">
                            <div className="flex-fill">
                                <div className="d-flex align-items-center text-left">
                                    <div className="mr-3">
                                        <img src={IconFileTextCircleWhite} alt="icon"/>
                                    </div>
                                    <div className="">
                                        <p className="title text-white m-0">Resumes Built Total</p>
                                        <h2 className="counts text-white m-0">{this.state.stats_build}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-fill">
                                <div className="d-flex align-items-center text-left">
                                    <div className="mr-3">
                                        <img src={IconUsersCircleWhite} alt="icon"/>
                                    </div>
                                    <div className="">
                                        <p className="title text-white m-0">Registered Members</p>
                                        <h2 className="counts text-white m-0">{this.state.stats_members}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-fill">
                                <div className="d-flex align-items-center text-left">
                                    <div className="mr-3">
                                        <img src={IconFilesCircleWhite} alt="icon"/>
                                    </div>
                                    <div className="">
                                        <p className="title text-white m-0">Total Templates</p>
                                        <h2 className="counts text-white m-0">{this.state.stats_templates}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefits bg-white">
                    <div className="container">
                        <div className="">
                            <h2 className="mb-3">Benefits of using our resume builder</h2>
                            <p className="mb-5">Some core features of free online resume builder</p>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6">
                                <img className="mb-4" src={IconMagicCircle} alt="icon" height="64" />
                                <h4 className="mb-3">Easy to use</h4>
                                <p>
                                It’s easy and free to build your resume quickly. Just a few clicks and you are there to create your online resume.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <img className="mb-4" src={IconCogCircle} alt="icon" height="64" />
                                <h4 className="mb-3">It’s an important tool</h4>
                                <p>
                                As you’ve created your resume once, you can share, print, download your resume from here.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <img className="mb-4" src={IconFilesCircle} alt="icon" height="64" />
                                <h4 className="mb-3">Use different kinds of templates</h4>
                                <p>
                                Various kinds of stylish templates you can use for different purposes and job positions. Also we’ll be guiding you with what to do and what to put in your resume.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <img className="mb-4" src={IconHeartCircle} alt="icon" height="64" />
                                <h4 className="mb-3">It’s free or cheaper</h4>
                                <p>
                                Some of the templates are completely free and so are much cheaper to access
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="bg-yellow hw">
                    <div className="container">
                        <h3 className="text-center mb-5">How it works</h3>
                        <div className="text-center">
                            <Slider {...settings}>
                                <div className="col-md-6">
                                    <img className="m-auto" src={IconUserPlusCircleYellow} alt="icon" height="74" />
                                    <h4 className="mb-3 mt-4">Register in a Flash</h4>
                                    <p>
                                    Click on ‘Create resume now’, some basic information of yours and you are registered here.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img className="m-auto" src={IconSigninCircleYellow} alt="icon" height="74" />
                                    <h4 className="mb-3 mt-4">Login and complete your resume</h4>
                                    <p>
                                    Login and you’ll be directed to a one page resume builder screen where you need to put your education, employment, skills, interest etc to complete your resume. You’ll also see edit and delete options there at the right corner of each segment.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img className="m-auto" src={IconFilePhotoCircleYellow} alt="icon" height="74" />
                                    <h4 className="mb-3 mt-4">Upload avatar</h4>
                                    <p>
                                    Finally you’ll find a screen to upload your image. If you don’t want to upload it now then it’s okay. You can click setting and upload your image/avatar from there afterwards. 
                                    </p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="bg-white faq">
                    <div className="container">
                        <h3 className="text-center mb-5">Frequently Asked Questions</h3>
                        <div className="d-flex justify-content-center">
                            <div className="col-md-8">
                                <div className="accordion" id="accordionFaq">
                                    <div className="card mb-3">
                                        <div className="card-header border-0" id="headingOne">
                                            <h4 className="mb-0">
                                                <a className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    1. How can I use resumion.com for free?
                                                </a>
                                            </h4>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionFaq">
                                            <div className="card-body pt-0">
                                                <strong>Ans:</strong> Resumion.com has a few different tools you can use 100% for free without entering any credit card details.
                                                <ul className="list-unstyled mt-2 ml-3">
                                                    <li>a. Create your account.</li>
                                                    <li>b. Fill up your basic information.</li>
                                                    <li>c. Download, share, print our basic resume template for free.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header border-0" id="headingTwo">
                                            <h4 className="mb-0">
                                                <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    2. How do I create my resume?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionFaq">
                                            <div className="card-body pt-0">
                                            <strong>Ans:</strong> First create an account and a basic resume is automatically created with your minimal information. Then you will be redirected to fill up your rest of the information. Also you can fill up later after login from your dashboard.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header border-0" id="headingThree">
                                            <h4 className="mb-0">
                                                <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    3. How  can I download or print or preview my resume?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionFaq">
                                            <div className="card-body pt-0">
                                            <strong>Ans:</strong> Login to your account and on the top menu portion you’ll find dashboard link.Go to dashboard and you’ll find download, print and preview option for free templates.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-header border-0" id="headingFour">
                                            <h4 className="mb-0">
                                                <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                                    4. Can I share my resume to social media?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionFaq">
                                            <div className="card-body pt-0">
                                            <strong>Ans:</strong> You can share your CV to different social media from “My Resume” portion in the dashboard, same place your can download, print and preview as well.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-header border-0" id="headingFive">
                                            <h4 className="mb-0">
                                                <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                                                    5. Can I create a shareable public link?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionFaq">
                                            <div className="card-body pt-0">
                                            <strong>Ans:</strong> Yes, when you go to “My Resume” portion from dashboard, you’ll find an option share. After clicking share, an auto generated link will be provided to you which is a shareable public link for you.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header border-0" id="headingSix">
                                            <h4 className="mb-0">
                                                <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    6. Can I change my resume language?
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionFaq">
                                            <div className="card-body pt-0">
                                            <strong>Ans:</strong> Right now we prefer you create your resume in English but you can put any language as information you seem fit.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-primary home-c2a text-center">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-6">
                            <h3 className="text-white mb-4">Let's start making your Resume</h3>
                            <p className="text-white">Our powerful resume builder tool will give you the possibilities to create your next job-winning resume, so what are you waiting for?</p>
                            {this.props.user_credentials.success === 1 ? (
                                <Link className="btn btn-dark btn-lg mb-5" to='/my-resume'>
                                    Edit your resume
                                </Link>) : (
                                <Link className="btn btn-dark btn-lg mb-5" to='/builder'>
                                    Create resume now
                                </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <SupportFloating />
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials
})

export default connect(mapStateToProps, { isLoggedIn })(Home);