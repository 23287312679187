import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchViewResume, updateViewResumeID } from '../actions/ViewResumeActions';
import { isLoggedIn } from '../actions/CommonActions';
import { removeLoaders } from './CommonFunctions';
import Objective from './ViewResume/Objective';
import PersonalDetails from './ViewResume/PersonalDetails';
import Skills from './ViewResume/Skills';
import Languages from './ViewResume/Languages';
import Education from './ViewResume/Education';
import Employments from './ViewResume/Employments';
import Courses from './ViewResume/Courses';
import References from './ViewResume/References';
import Interests from './ViewResume/Interests';
import CustomArea from './ViewResume/CustomArea';
import Footer from './Footer';
import { IconFileAlt, IconShareDark, IconDownloadDark } from '../icon/index';
import { Link, Redirect } from "react-router-dom";
import MetaTags from 'react-meta-tags';

class Resume extends Component {
    state = {
        details_view: '',
        basicinfo: null,
        objective: null,
        education: null,
        employments: null,
        courses: null,
        skills: null,
        languages: null,
        references: null,
        interests: null,
        customareas: null,
        job_category_list: null,
        userid: '',
        resumeid: ''
        // employment: '',
        // hobbies: '',
        // languages: '',
        // education: '',
        // skills: '',
        // references: '',
        // interests: ''
    }

    componentDidMount() {
        this.props.fetchViewResume(this.props.match.params.resumeid);
        // this.props.updateViewResumeID(this.props.match.params.resumeid);
        removeLoaders();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.resumeid === '') {
            this.setState({
                resumeid: this.props.match.params.resumeid
            })
        }
        if (prevState.basicinfo === null) {
            if (this.props.details_view.basicinfo !== undefined) {
                this.setState({
                    ...this.state,
                    basicinfo: this.props.details_view.basicinfo[0]
                })
            }
        }
        if (prevState.objective === null) {
            if (this.props.details_view.objective !== undefined) {
                this.setState({
                    ...this.state,
                    objective: this.props.details_view.objective[0]
                })
            }
        }
        if (prevState.skills === null) {
            if (this.props.details_view.skills !== undefined) {
                this.setState({
                    ...this.state,
                    skills: this.props.details_view.skills
                })
            }
        }
        if (prevState.education === null) {
            if (this.props.details_view.education !== undefined) {
                this.setState({
                    ...this.state,
                    education: this.props.details_view.education
                })
            }
        }
        if (prevState.employments === null) {
            if (this.props.details_view.employments !== undefined) {
                this.setState({
                    ...this.state,
                    employments: this.props.details_view.employments
                })
            }
        }
        if (prevState.courses === null) {
            if (this.props.details_view.courses !== undefined) {
                this.setState({
                    ...this.state,
                    courses: this.props.details_view.courses
                })
            }
        }
        if (prevState.languages === null) {
            if (this.props.details_view.languages !== undefined) {
                this.setState({
                    ...this.state,
                    languages: this.props.details_view.languages
                })
            }
        }
        if (prevState.references === null) {
            if (this.props.details_view.references !== undefined) {
                this.setState({
                    ...this.state,
                    references: this.props.details_view.references
                })
            }
        }
        if (prevState.interests === null) {
            if (this.props.details_view.interests !== undefined) {
                this.setState({
                    ...this.state,
                    interests: this.props.details_view.interests
                })
            }
        }
        if (prevState.customareas === null) {
            if (this.props.details_view.customareas !== undefined) {
                this.setState({
                    ...this.state,
                    customareas: this.props.details_view.customareas
                })
            }
        }


    }

    render() {
        // console.log(this.props.details_view.customarea)
        // console.log(this.state)

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }
        // console.log(this.props.details_view.objective)
        // console.log(success_redux, session_success)
        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }


        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>My Resume - Resumion</title>
                        <meta property="og:title" content="My Resume - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className='container'>
                    {/* <div className="d-flex justify-content-center"> */}
                    <div className="row">
                        <div className="col-sm-8 col-lg-9">
                            <PersonalDetails basicinfo={this.state.basicinfo} />
                            <Objective objective={this.state.objective} />
                            <Education education={this.state.education} resumeid={this.props.match.params.resumeid}/>
                            <Employments employments={this.state.employments} resumeid={this.props.match.params.resumeid}/>
                            <Courses courses={this.state.courses} resumeid={this.props.match.params.resumeid}/>
                            <Skills skills={this.state.skills} resumeid={this.props.match.params.resumeid} />
                            <Languages languages={this.state.languages} resumeid={this.props.match.params.resumeid}/>
                            <References references={this.state.references} resumeid={this.props.match.params.resumeid}/>
                            <Interests interests={this.state.interests} resumeid={this.props.match.params.resumeid}/>
                            <CustomArea customareas={this.state.customareas} resumeid={this.props.match.params.resumeid}/>
                            {/* <Hobbies hobbies={this.state.hobbies} /> */}
                        </div>
                        <div className="col-sm-4 col-lg-3">
                            <div className='card mb-4 mt-4'>
                                <div className='card-body'>
                                    <Link type="button" to={`/preview-resume/${this.props.match.params.resumeid}/${this.props.match.params.templateid}`} className="btn btn-light btn-sm btn-block"><img src={IconFileAlt} height="20" alt='preview' /> Preview</Link>
                                    <Link type="button" to={`/share-resume/${this.props.match.params.resumeid}`} className="btn btn-light btn-sm btn-block"><img src={IconShareDark} height="20" alt='share' /> Share</Link>
                                    <a rel="noopener noreferrer" target="_blank" href={`https://resumion.com/apiv2/DownloadResume.php?resumeid=${this.props.match.params.resumeid}&templateid=${this.props.match.params.templateid}&actiontype=download`} className="btn btn-light btn-sm btn-block"><img src={IconDownloadDark} height="20" alt='download' /> Download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    details_view: state.VIEW_RES_REDU.details_view ? state.VIEW_RES_REDU.details_view : '',
    resumeid_details_view: state.VIEW_RES_REDU.resumeid_details_view
})

export default connect(mapStateToProps, { isLoggedIn, fetchViewResume, updateViewResumeID })(Resume);