import React, { Component } from 'react';
import { getMonth } from '../CommonFunctions';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import BtnLoader from '../../BtnLoader';
import { IconCertificate, IconTrash, IconPlusCircleWhite } from '../../icon/index';
import EmptyState from '../../components/EmptyState';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class Courses extends Component {
    state = {
        resumeid: null,
        courses: null,
        view: true,
        edit: false,
        add_new: false,
        coursetitle: [],
        institution: [],
        location: [],
        start_month: [],
        start_year: [],
        id_edit: null,
        institution_edit: null,
        coursetitle_edit: null,
        location_edit: null,
        level_edit: null,
        start_year_edit: null,
        end_year_edit: null,
        start_month_edit: null,
        end_month_edit: null,
        description_edit: null,
        layout_loader: true,
        isDisabled: 'disabled',
        btn_loader: false
    }
    componentDidMount() {
        // console.log(getResumeidFromSession())
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.layout_loader === true) {
            this.setState({
                ...this.state,
                layout_loader: false
            })
        }
        if (prevState.courses === null && this.props.courses !== null) {
            if (this.props.courses !== undefined) {
                this.setState({
                    ...this.state,
                    courses: this.props.courses
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    notifySuccess = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    getDropList = () => {
        const year = 1950;
        return (
            Array.from(new Array(90), (v, i) =>
                <option key={i} value={year + i}>{year + i}</option>
            )
        );
    };
    addSkill = (e) => {
        e.preventDefault();
        this.props.insertEmploymentForm();
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
        // console.log(this.state)
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsEdit())
    }
    checkMandatoryFields = () => {
        const courseLength = this.state.coursetitle.length;
        const institutionLength = this.state.institution.length;
        const locationLength = this.state.location.length;
        const startMonthLength = this.state.start_month.length;
        const startYearLength = this.state.start_year.length;
        
        courseLength !== 0 && institutionLength !== 0 && locationLength !== 0 && startMonthLength !== 0 && startYearLength !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }
    checkMandatoryFieldsEdit = () => {
        const courseLength = this.state.coursetitle_edit.length;
        const institutionLength = this.state.institution_edit.length;
        const locationLength = this.state.location_edit.length;
        const startMonthLength = this.state.start_month_edit.length;
        const startYearLength = this.state.start_year_edit.length;
        
        courseLength !== 0 && institutionLength !== 0 && locationLength !== 0 && startMonthLength !== 0 && startYearLength !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }
    handleAddCourse = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true
        })
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            coursetitle: this.state.coursetitle,
            institution: this.state.institution,
            location: this.state.location,
            start_year: this.state.start_year,
            end_year: this.state.end_year,
            start_month: this.state.start_month,
            end_month: this.state.end_month,
            description: this.state.description
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddCourse.php', qs.stringify(data), config)
            .then(res => res.data.data[0].course[0])
            // .then(res => console.log(res))

            .then(res => this.setState({
                ...this.state,
                view: true,
                edit: false,
                add_new: false,

                coursetitle: '',
                institution: '',
                location: '',
                start_year: '',
                end_year: '',
                start_month: '',
                end_month: '',
                description: '',
                courses: [...this.state.courses, res]
            }))
            .then(() => this.notifySuccess())
            .then(() => this.endBtnLoader())
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            id: this.state.id_edit,
            coursetitle: this.state.coursetitle_edit,
            institution: this.state.institution_edit,
            location: this.state.location_edit,
            start_year: this.state.start_year_edit,
            end_year: this.state.end_year_edit,
            start_month: this.state.start_month_edit,
            end_month: this.state.end_month_edit,
            description: this.state.description_edit
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetCourse.php', qs.stringify(data), config)
            .then(res => res.data.data[0].course[0])
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.courses.map(course => course.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let courses = [...this.state.courses];
                let course = { ...courses[indices] };
                course = res;
                courses[indices] = course;
                this.setState({
                    view: true,
                    edit: false,
                    courses: courses
                });
            })
            .then(() => this.notifySuccess())
            .then(() => this.endBtnLoader())
    }

    handleDelete = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'course'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    // .then(res => console.log(res))
                const courses = this.state.courses.filter(course => course.id !== id);
                this.setState({ courses: courses });
                this.notifyDelete();
            }
        })
    }

    handleEdit = (id) => {
        const courses = this.state.courses.filter(course => course.id === id);
        this.setState({
            view: false,
            edit: true,
            coursetitle_edit: courses[0].coursetitle,
            id_edit: courses[0].id,
            institution_edit: courses[0].institution,
            location_edit: courses[0].location,
            start_month_edit: courses[0].start_month,
            start_year_edit: courses[0].start_year,
            end_month_edit: courses[0].end_month,
            end_year_edit: courses[0].end_year,
            description_edit: courses[0].description,
            isDisabled: ''
        })
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddCourse = this.handleAddCourse.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        
        // console.log(this.props.courses)

        // console.log(this.state)
        // console.log(this.props)
        if (this.state.courses !== null) {
            var courses = this.state.courses.map((course, i, arr) => {
                return (
                    <React.Fragment key={course.id}>
                        <div className="row">
                            <div className="col-md-8">
                                <h5 className="course-title">{course.coursetitle}</h5>
                                <h6 className="institution-location">
                                    {course.institution + ", " + course.location}
                                    <span className="course-duration">
                                         / 
                                        {course.start_month !== null && getMonth(course.start_month)}
                                        {course.start_year !== null && ", " + course.start_year}
                                        
                                        {course.end_month !== null && " - " + getMonth(course.end_month)}
                                        {course.end_year !== null && ", " + course.end_year}
                                    </span>
                                </h6>
                            </div>

                            <div className="ml-auto col-md-2 col-sm-12 text-md-right text-sm-left">
                                <button onClick={this.handleEdit.bind(this, course.id)} className="btn btn-link-edit p-0"> <img src={IconEdit} alt="edit" height="20"/></button>
                                <button onClick={this.handleDelete.bind(this, course.id)} className="btn btn-link-delete p-0 ml-2"> <img src={IconTrashSolid} alt="delete" height="20"/></button>
                            </div>
                            <div className="w-100"></div>
                            <div className="col">
                                {course.description !== '' &&
                                    <TextareaAutosize className="form-control view-textarea p-0 border-0 bg-white" defaultValue={course.description} readOnly="readonly"/>
                                }
                            </div>
                        </div>
                        {arr.length !== i + 1 &&
                            <hr className="mt-3 mb-3" />
                        }
                    </React.Fragment>
                )
            })
            // console.log(this.state.course.length)
        }
        return (
            <div className="card skills mb-4">

                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconCertificate} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">Courses</h5>
                                        <p className="text-muted mb-0">Add your training/ courses/ workshops.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view && this.state.courses !== null &&
                                    <React.Fragment>
                                        {courses}
                                        {this.state.courses.length === 0 &&
                                            <div className="text-center">
                                                <EmptyState />
                                                <button onClick={this.handleAddCourse} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                            </div>
                                        }
                                        {this.state.courses.length !== 0 &&
                                            <button onClick={this.handleAddCourse} className="btn btn-primary mt-3"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="coursetitle_edit">Course Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="coursetitle_edit" placeholder="e.g. Mastering in UX Design" value={this.state.coursetitle_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="institution">Institute <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="institution_edit" placeholder="e.g. California College of the Arts" value={this.state.institution_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="location">Location <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="location_edit" placeholder="e.g. Babylon" value={this.state.location_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="start_month_edit">Start Date <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="start_month_edit" onChange={this.handleChangeEdit} value={this.state.start_month_edit} multiple={false}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="start_year_edit" onChange={this.handleChangeEdit} value={this.state.start_year_edit} multiple={false}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label htmlFor="end_month_edit">Ending Date (or expected)</label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select value={this.state.end_month_edit} className="form-control" name="end_month_edit" onChange={this.handleChangeEdit} multiple={false}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="end_year_edit" value={this.state.end_year_edit} onChange={this.handleChangeEdit} multiple={false}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label htmlFor="description_edit">Description</label>
                                                <textarea row="5" onChange={this.handleChangeEdit} type="text" className="form-control" name="description_edit" placeholder="Write description here" defaultValue={this.state.description_edit}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="coursetitle">Course Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="coursetitle" placeholder="e.g. Mastering in UX Design" value={this.state.coursetitle} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="institution">Institute <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="institution" placeholder="e.g. California College of the Arts" value={this.state.institution} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="location">Location <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="location" placeholder="e.g. Babylon" value={this.state.location} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="start_month">Start Date <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="start_month" value={this.state.start_month} multiple={false}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="start_year" value={this.state.start_year} multiple={false}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="end_month">Ending Date (or expected)</label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="end_month" value={this.state.end_month} multiple={false}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="end_year" value={this.state.end_year} multiple={false}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="">Description</label>
                                                <textarea row="5" onChange={this.handleChange} type="text" className="form-control" name="description" placeholder="Write description here" defaultValue={this.state.description}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(Courses);