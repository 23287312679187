import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { fetchViewResume, updateViewResumeID } from '../../actions/ViewResumeActions';
import { updateUserCredentialsInSession, getResumeidFromSession, getAvatarFromSession, isNull, onlyNumber } from '../CommonFunctions';
import { isLoggedIn } from '../../actions/CommonActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Link } from 'react-router-dom';
// import PersonalDetailsEdit from './PersonalDetailsEdit';
import axios from 'axios';
import LayoutLoader from '../../LayoutLoader';
import qs from 'querystring';
import { toast } from 'react-toastify';
import { IconAvatar, IconFileText, IconEditCircle } from '../../icon/index';
import BtnLoader from '../../BtnLoader';

import { 
    IconEdit
 } from '../../icon/index';

class PersonalDetails extends Component {
    state = {
        view: true,
        edit: false,
        resumeid: null,
        basicinfo_id: null,
        title: null,
        gender: null,
        first_name: null,
        last_name: null,
        category: null,
        category_name: null,
        contact_number: null,
        email: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        dob: null,
        nationality: null,
        layout_loader: true,
        category_list: null,
        job_category_list: null,
        category_name_temp: null,
        avatar: '',

        title_edit: null,
        gender_edit: null,
        first_name_edit: null,
        last_name_edit: null,
        category_edit: null,
        category_name_edit: null,
        contact_number_edit: null,
        email_edit: null,
        address_edit: null,
        city_edit: null,
        state_edit: null,
        zip_edit: null,
        country_edit: null,
        dob_edit: null,
        nationality_edit: null
    }

    checkMandatoryFieldsEdit = () => {
        const first_name_edit = this.state.first_name_edit;
        const last_name_edit = this.state.last_name_edit;
        const category_name_edit = this.state.category_name_edit;
        const contact_number_edit = this.state.contact_number_edit;
        // console.log(objective, objectiveEdit)
        
        first_name_edit !== '' && last_name_edit !== '' && category_name_edit !== '' && contact_number_edit !== '' ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }


    componentDidUpdate(prevProps, prevState) {
        // console.log(prevState.layout_loader)
        if (prevState.layout_loader === true) {
            this.setState({
                ...this.state,
                layout_loader: false
            })
        }
        if (prevState.first_name === null && this.props.basicinfo !== null && this.props.basicinfo.first_name !== null) {
            this.setState({
                ...this.state,
                first_name: this.props.basicinfo.first_name
            })
        }
        if (prevState.last_name === null && this.props.basicinfo !== null && this.props.basicinfo.last_name !== null) {
            this.setState({
                ...this.state,
                last_name: this.props.basicinfo.last_name
            })
        }
        if (prevState.title === null && this.props.basicinfo !== null && this.props.basicinfo.title !== null) {
            if (this.props.basicinfo !== undefined) {
                this.setState({
                    ...this.state,
                    title: this.props.basicinfo.title
                })
            }
        }

        if (prevState.category === null && this.props.basicinfo !== null && this.props.basicinfo.category !== null) {
            this.setState({
                ...this.state,
                category: this.props.basicinfo.category
            })
        }
        if (prevState.category_name === null && this.props.basicinfo !== null && this.props.basicinfo.category_name !== null) {
            this.setState({
                ...this.state,
                category_name: this.props.basicinfo.category_name
            })
        }

        if (prevState.gender === null && this.props.basicinfo !== null && this.props.basicinfo.gender !== null) {
            this.setState({
                ...this.state,
                gender: this.props.basicinfo.gender
            })
        }
        if (prevState.contact_number === null && this.props.basicinfo !== null && this.props.basicinfo.contact_number !== null) {
            this.setState({
                ...this.state,
                contact_number: this.props.basicinfo.contact_number
            })
        }
        if (prevState.email === null && this.props.basicinfo !== null && this.props.basicinfo.email !== null) {
            this.setState({
                ...this.state,
                email: this.props.basicinfo.email
            })
        }

        if (prevState.address === null && this.props.basicinfo !== null && this.props.basicinfo.address !== null) {
            this.setState({
                ...this.state,
                address: this.props.basicinfo.address
            })
        }
        if (prevState.city === null && this.props.basicinfo !== null && this.props.basicinfo.city !== null) {
            this.setState({
                ...this.state,
                city: this.props.basicinfo.city
            })
        }
        if (prevState.state === null && this.props.basicinfo !== null && this.props.basicinfo.state !== null) {
            this.setState({
                ...this.state,
                state: this.props.basicinfo.state
            })
        }
        if (prevState.zip === null && this.props.basicinfo !== null && this.props.basicinfo.zip !== null) {
            this.setState({
                ...this.state,
                zip: this.props.basicinfo.zip
            })
        }
        if (prevState.country === null && this.props.basicinfo !== null && this.props.basicinfo.country !== null) {
            this.setState({
                ...this.state,
                country: this.props.basicinfo.country
            })
        }
        if (prevState.dob === null && this.props.basicinfo !== null && this.props.basicinfo.dob !== null) {
            this.setState({
                ...this.state,
                dob: new Date(this.props.basicinfo.dob)
            })
        }
        if (prevState.nationality === null && this.props.basicinfo !== null && this.props.basicinfo.nationality !== null) {
            this.setState({
                ...this.state,
                nationality: this.props.basicinfo.nationality
            })
        }

        if (prevState.job_category_list === null) {
            this.setState({
                ...this.state,
                job_category_list: this.props.job_category_list
            })
        }
        if (prevState.avatar === '' && this.props.basicinfo !== null) {
            this.setState({
                avatar: this.props.basicinfo.avatar
            })
        }
        this.state.basicinfo_id === null &&
            this.setState({
                ...this.state,
                basicinfo_id: this.props.basicinfo.id
            }) 
        // console.log(prevState.avatar, this.props.basicinfo.avatar)
    }

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: ''
        })
    }

    selectCountry(val) {
        this.setState({ country_edit: val });
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsEdit())
    }
    handleChangeCategory = (e) => {
        e.preventDefault();
        var index = e.nativeEvent.target.selectedIndex;
        this.setState({
            [e.target.name]: e.target.value,
            category_name_temp: e.target[index].text
        })
    }
    handleChangeDate = (date) => {
        this.setState({
            ...this.state,
            dob_edit: date
        });
    };

    handleEditPersonalDetails = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            title_edit: this.state.title,
            gender_edit: this.state.gender,
            first_name_edit: this.state.first_name,
            last_name_edit: this.state.last_name,
            category_edit: this.state.category,
            contact_number_edit: this.state.contact_number,
            email_edit: this.state.email,
            address_edit: this.state.address,
            city_edit: this.state.city,
            state_edit: this.state.state,
            zip_edit: this.state.zip,
            country_edit: this.state.country,
            dob_edit: new Date(this.state.dob),
            nationality_edit: this.state.nationality
        })
        axios.get('https://resumion.com/apiv2/GetJobCategories.php')
            .then(res => res.data.data[0])
            .then(res => this.setState({
                category_list: res
            }))

        this.setState({
            view: false,
            edit: true,
            category_name_temp: this.state.category_name
        })
    }
    handleCancelPersonalDetails = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false
        })
    }
    IsSelected = (value1, value2) => {
        if (value1 === value2) {
            return 'selected';
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.basicinfo_id,
            gender: this.state.gender_edit,
            title: this.state.title_edit,
            category: this.state.category_edit,
            first_name: this.state.first_name_edit,
            last_name: this.state.last_name_edit,
            contact_number: this.state.contact_number_edit,
            address: this.state.address_edit,
            dob: this.state.dob_edit.toLocaleDateString(),
            nationality: this.state.nationality_edit,
            country: this.state.country_edit,
            city: this.state.city_edit,
            zip: this.state.zip_edit,
            state: this.state.state_edit,
            // email: this.state.email_edit
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetBasicInfo.php', qs.stringify(data), config)
            // Removing feature single resume [account name updates] - R:1
            // .then(updateUserCredentialsInSession(this.state.first_name_edit, this.state.last_name_edit, this.state.title_edit))
            // R:1
            .then(this.props.isLoggedIn())
            .then(() => this.setState({
                view: true,
                edit: false,
                category_name: this.state.category_name_temp,
                gender: this.state.gender_edit,
                title: this.state.title_edit,
                first_name: this.state.first_name_edit,
                last_name: this.state.last_name_edit,
                contact_number: this.state.contact_number_edit,
                address: this.state.address_edit,
                dob: this.state.dob_edit,
                nationality: this.state.nationality_edit,
                country: this.state.country_edit,
                city: this.state.city_edit,
                zip: this.state.zip_edit,
                state: this.state.state_edit,
                email: this.state.email_edit
            }))
            .then(() => this.endBtnLoader())
            .then(() => this.notifySuccess())
    }

    notifySuccess = () => {
        toast("Success!", { autoClose: 5000 });
    };

    render() {
        // console.log(this.props)
        // console.log(this.state)
        const {
            title,
            first_name,
            last_name,
            gender,
            category,
            avatar,
            category_name,
            contact_number,
            email,
            address,
            city,
            state,
            zip,
            country,
            dob,
            nationality
        } = this.state
        // console.log(this.props.basicinfo.category)
        // console.log(this.props.details_view.basicinfo[0])
        // console.log(this.props.basicinfo)
        // console.log(this.state)
        // console.log(this.props)

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEdit = this.handleChangeEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // var get_dob = new Date(this.state.dob)



        if (this.state.category_list !== null) {
            var category_list = this.state.category_list.category.map(category => {
                return (
                    <option key={category.id} value={category.id}>{category.category_name}</option>
                )
            })
        }

        // console.log(this.state)

        return (
            <div className="card personal-details mb-4 mt-4" >
                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <h5 className="text-primary">
                                    <img src={IconFileText} alt="file" height="24" /> Personal details
                            </h5>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view &&
                                    <div className="personal-details-view">
                                        <div className="user-info">
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <div className="user-avater position-relative">
                                                        <img className="rounded-circle" src={avatar !== null ? (
                                                            'http://resumion.com/images/users/' + avatar
                                                        ) : (IconAvatar)} alt="avatar" height="24" />
                                                        <Link to="/account-settings">
                                                            <img className="edit-circle position-absolute" src={IconEditCircle} alt="edit avatar" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <h5 className="user-name pt-2">
                                                        {isNull(first_name) + " " + isNull(last_name)}
                                                    </h5>
                                                    <div className="user-title">
                                                        {isNull(title)}
                                                    </div>
                                                </div>
                                                <div className="ml-auto text-md-right text-sm-left">
                                                    <button onClick={this.handleEditPersonalDetails.bind(this)} className="btn btn-link-edit p-0">
                                                        <img src={IconEdit} alt="edit" height="20"/>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="user-general-info">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Phone</label>
                                                    <p className="phone-number m-0">{isNull(contact_number)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Email Address</label>
                                                    <p className='m-0'>{email}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Job Category</label>
                                                    <p className='m-0'>{isNull(category_name)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Gender</label>
                                                    <p className='m-0'>{isNull(gender)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Address</label>
                                                    <p className='m-0'>{isNull(address)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">City</label>
                                                    <p className='m-0'>{isNull(city)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">State</label>
                                                    <p className='m-0'>{isNull(state)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Zip</label>
                                                    <p className='m-0'>{isNull(zip)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Country</label>
                                                    <p className='m-0'>{isNull(country)}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Date of Birth</label>
                                                    {/* <div>{dob !== null ? (this.state.do.toLocaleDateString()) : ('sdfsdf')}</div> */}
                                                    <p className="d-block m-0">{this.state.dob.toLocaleDateString()}</p>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <label htmlFor="">Nationality</label>
                                                    <p className='m-0'>{isNull(nationality)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.edit &&
                                    <form className="personal-details-edit" onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="">First Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="first_name_edit" placeholder="Enter first name" value={this.state.first_name_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4">Last Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="last_name_edit" placeholder="Enter last name" value={this.state.last_name_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputAddress">Title/ Designation</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="title_edit" placeholder="Ex: Web Developer" value={this.state.title_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputAddress">Date of Birth</label>
                                                {/* <input onChange={this.handleChangeEdit} type="text" className="form-control" name="dateofbirth" placeholder="YYYY-MM-DD" value={this.state.dob} /> */}
                                                <DatePicker
                                                    className="form-control w-100"
                                                    name="dob_edit"
                                                    selected={this.state.dob_edit}
                                                    onChange={this.handleChangeDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputAddress">Gender</label>
                                                <select value={this.state.gender_edit} onChange={this.handleChange} type="text" className="form-control" name="gender_edit">
                                                    <option value="">Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputAddress">Job Category  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <select value={this.state.category_edit} onChange={this.handleChangeCategory} type="text" className="form-control" name="category_edit">
                                                    {category_list}
                                                </select>
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputAddress2">Nationality</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="nationality_edit" placeholder="e.g. American" value={this.state.nationality_edit} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="inputAddress">Address</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="address_edit" placeholder="e.g. 3825 Wayback Lane" value={this.state.address_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">City</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="city_edit" placeholder="e.g. Babylon" value={this.state.city_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">State</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="state_edit" placeholder="e.g. New York" value={this.state.state_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputAddress2">Zip Code</label>
                                                <input onChange={this.handleChangeEdit} onKeyPress={onlyNumber} type="text" className="form-control" name="zip_edit" placeholder="e.g. 11702" value={this.state.zip_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCountry">Country</label>
                                                <CountryDropdown className="form-control" name="country_edit" value={this.state.country_edit} onChange={(val) => this.selectCountry(val)} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Phone <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} onKeyPress={onlyNumber} type="text" className="form-control" name="contact_number_edit" placeholder="e.g. 6313769608" value={this.state.contact_number_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">Email Address <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="email_edit" placeholder="e.g. name@email.com" readOnly value={this.state.email} />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-2" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancelPersonalDetails} className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div >
        );
    }
}

const mapStateToProps = state => ({
    // job_category_list: state.COM_REDU.job_category_list
    // details_view: state.DETAILS_VIEW_REDU.details_view ? state.DETAILS_VIEW_REDU.details_view : ''
    // profilePhoto : state.user.userData ? state.user.userData.photos.primary : ''
    resumeid_details_view: state.VIEW_RES_REDU.resumeid_details_view
})

// export default connect(mapStateToProps, null)(PersonalDetails);
export default connect(mapStateToProps, { fetchViewResume, isLoggedIn, updateViewResumeID })(PersonalDetails);