import React from 'react';
import { Redirect, Route } from "react-router-dom";

// if (localStorage.getItem("user") !== null) {
//     var session_success = JSON.parse(localStorage.getItem("user"))[2]
// }

// export const PrivateRoute = ({ component: Component, session_success, ...rest }) => (
//     <Route {...rest} render={(props) => (
//         session_success === 1 ? (<Component {...props} />)
//             : (< Redirect to='/my-resume' />)
//     )} />
// )

export const PrivateRoute = ({component: Component, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            JSON.parse(localStorage.getItem("user"))[2] !== '' ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};