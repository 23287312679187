import React, { Component } from 'react';
import { getMonth } from '../CommonFunctions';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import { IconTrash, IconPlusCircleWhite, IconBriefcase } from '../../icon/index';
import EmptyState from '../../components/EmptyState';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import BtnLoader from '../../BtnLoader';
import TextareaAutosize from 'react-textarea-autosize';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class Employments extends Component {
    state = {
        resumeid: null,
        employments: null,
        view: true,
        edit: false,
        add_new: false,
        company: [],
        jobtitle: [],
        location: [],
        start_year: [],
        end_year: [],
        start_month: [],
        end_month: [],
        description: [],
        currently_working: false,
        id_edit: null,
        company_edit: null,
        jobtitle_edit: null,
        location_edit: null,
        start_year_edit: null,
        end_year_edit: null,
        start_month_edit: null,
        end_month_edit: null,
        description_edit: null,
        currently_working_edit: null,
        layout_loader: true,
        isDisabled: 'disabled'
    }
    componentDidMount() {
        // console.log(getResumeidFromSession())
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.layout_loader === true) {
            this.setState({
                ...this.state,
                layout_loader: false
            })
        }
        if (prevState.employments === null && this.props.employments !== null) {
            if (this.props.employments !== undefined) {
                this.setState({
                    ...this.state,
                    employments: this.props.employments
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }


    }
    notifySuccess = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }

    getDropList = () => {
        const year = 1950;
        return (
            Array.from(new Array(90), (v, i) =>
                <option key={i} value={year + i}>{year + i}</option>
            )
        );
    };
    addSkill = (e) => {
        e.preventDefault();
        this.props.insertEmploymentForm();
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }

    handleChangeCw = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.checked
        })
    }


    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsEdit())
    }

    handleChecked(e) {
        this.setState({
            currently_working: !this.state.currently_working,
            end_month: '',
            end_year: '',
        });
        // this.setState({isChecked: !this.state.isChecked});
        // this.setState({[e.target.name]: e.target.value});
    }

    handleCheckedEdit(e) {
        this.setState({
            currently_working_edit: !this.state.currently_working_edit,
            end_month_edit: '',
            end_year_edit: '',
        });
    }



    checkMandatoryFields = () => {
        const jobTitleLength = this.state.jobtitle.length;
        const companyLength = this.state.company.length;
        const locationLength = this.state.location.length;
        const startMonthLength = this.state.start_month.length;
        const startYearLength = this.state.start_year.length;
        {
            jobTitleLength !== 0 && companyLength !== 0 && locationLength !== 0 && startMonthLength !== 0 && startYearLength !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )
        }
    }
    checkMandatoryFieldsEdit = () => {
        const titleLength = this.state.jobtitle_edit.length;
        const companyLength = this.state.company_edit.length;
        const locationLength = this.state.location_edit.length;
        const startMonthLength = this.state.start_month_edit.length;
        const startYearLength = this.state.start_year_edit.length;
        // console.log(degreeLength)
        {
            titleLength !== 0 && companyLength !== 0 && locationLength !== 0 && startMonthLength !== 0 && startYearLength !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )
        }
    }
    handleAdd = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true,
            currently_working_edit: false
        })
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            company: this.state.company,
            jobtitle: this.state.jobtitle,
            location: this.state.location,
            start_year: this.state.start_year,
            end_year: this.state.end_year,
            start_month: this.state.start_month,
            end_month: this.state.end_month,
            description: this.state.description,
            currently_working: this.state.currently_working
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddEmployment.php', qs.stringify(data), config)
            .then(res => res.data.data[0].employment[0])
            // .then(res=>console.log(res))
            .then(res => this.setState({
                ...this.state,
                view: true,
                edit: false,
                add_new: false,

                company: '',
                jobtitle: '',
                location: '',
                start_year: '',
                end_year: '',
                start_month: '',
                end_month: '',
                description: '',
                currently_working: false,

                employments: [...this.state.employments, res]
            }))
            .then(() => this.notifySuccess())
            .then(() => this.endBtnLoader())
        // .catch(error => {
        //     console.log(error)
        // })
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            id: this.state.id_edit,
            company: this.state.company_edit,
            jobtitle: this.state.jobtitle_edit,
            location: this.state.location_edit,
            start_year: this.state.start_year_edit,
            end_year: this.state.end_year_edit,
            start_month: this.state.start_month_edit,
            end_month: this.state.end_month_edit,
            description: this.state.description_edit,
            currently_working: this.state.currently_working_edit
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetEmployment.php', qs.stringify(data), config)
            .then(res => res.data.data[0].employment[0])
            // .then(res => console.log(res)) don't console before accessing child
            // .then(res => console.log(res))
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.employments.map(employment => employment.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let employments = [...this.state.employments];
                let employment = { ...employments[indices] };
                employment = res;
                employments[indices] = employment;
                this.setState({
                    view: true,
                    edit: false,
                    employments: employments
                });
            })
            .then(() => this.notifySuccess())
            .then(() => this.endBtnLoader())

            .catch(error => console.log(error))
    }

    handleDelete = (id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'employment'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    // .then(res => console.log(res))
                const employments = this.state.employments.filter(employment => employment.id !== id);
                this.setState({ employments: employments });
                this.notifyDelete();
            }
        })
    }

    handleEdit = (id) => {
        const employments = this.state.employments.filter(employment => employment.id === id);
        this.setState({
            view: false,
            edit: true,

            id_edit: employments[0].id,
            company_edit: employments[0].company,
            jobtitle_edit: employments[0].jobtitle,
            location_edit: employments[0].location,
            start_year_edit: employments[0].start_year,
            end_year_edit: employments[0].end_year,
            currently_working_edit: employments[0].currently_working,
            start_month_edit: employments[0].start_month,
            end_month_edit: employments[0].end_month,
            description_edit: employments[0].description,

            isDisabled: ''
        })
    }
    checkValue = (value) => {
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChangeCw = this.handleChangeCw.bind(this);

        this.handleChecked = this.handleChecked.bind(this);
        this.handleCheckedEdit = this.handleCheckedEdit.bind(this);
        // this.handleEdit = this.handleEdit.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);

        // console.log(this.state)
        // console.log(this.props.employments)
        if (this.state.employments !== null) {
            var employments = this.state.employments.map((employment, i, arr) => {
                return (
                    <React.Fragment key={employment.id}>
                        <div className="row">
                            <div className="col-md-10">
                                <h5 className="jobtitle mb-1">
                                    {employment.jobtitle}
                                    {employment.currently_working === true && <span className="badge badge-dark ml-1 align-text-top rounded-pill small">Currently Working</span>}
                                </h5>
                                <h6 className="company mb-2">
                                    {employment.company + ", " + employment.location} 
                                    <span className="duration">
                                        / 
                                        {employment.start_month !== null && getMonth(employment.start_month)}
                                        {employment.start_year !== null && ", " + employment.start_year}
                                        
                                        {employment.end_month !== null && " - " + getMonth(employment.end_month)}
                                        {employment.end_year !== null && ", " + employment.end_year}
                                    </span>
                                </h6>
                            </div>

                            <div className="ml-auto col-md-2 col-sm-12 text-md-right text-sm-left">
                                <button onClick={this.handleEdit.bind(this, employment.id)} className="btn btn-link-edit p-0"> <img src={IconEdit} alt="edit" height="20"/></button>
                                <button onClick={this.handleDelete.bind(this, employment.id)} className="btn btn-link-delete p-0 ml-2"> <img src={IconTrashSolid} alt="delete" height="20"/></button>
                            </div>
                            <div className="w-100"></div>
                            <div className="col">
                                {/* <p>{employment.description}</p> */}
                                {employment.description !== '' &&
                                    <TextareaAutosize className="form-control view-textarea p-0 border-0 bg-white" defaultValue={employment.description} readOnly="readonly"/>
                                }
                            </div>
                        </div>
                        {arr.length !== i + 1 &&
                            <hr className="mt-3 mb-3" />
                        }
                    </React.Fragment>
                )
            })
            // console.log(this.state)
        }
        return (
            <div className="card skills mb-4">

                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconBriefcase} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">Employments</h5>
                                        <p className="text-muted mb-0">Add your employment history in order of latest joining and achievement/portfolio in the description section.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view && this.state.employments !== null && 
                                    <React.Fragment>
                                        {employments}
                                        {this.state.employments.length === 0 &&
                                            <div className="text-center">
                                                <EmptyState />
                                                <button onClick={this.handleAdd} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                            </div>
                                        }
                                        {this.state.employments.length !== 0 &&
                                            <button onClick={this.handleAdd} className="btn btn-primary mt-3"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="">Job Title <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="jobtitle_edit" placeholder="e.g. Python Developer" value={this.state.jobtitle_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4">Company <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="company_edit" placeholder="e.g. Applc" value={this.state.company_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="inputAddress">Location <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="location_edit" placeholder="e.g. Babylon" value={this.state.location_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Start Date <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="start_month_edit" onChange={this.handleChangeEdit} value={this.state.start_month_edit}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="start_year_edit" onChange={this.handleChangeEdit} value={this.state.start_year_edit}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-12">
                                                        <div className="form-check mt-3 pl-0">
                                                            <input onChange={this.handleCheckedEdit} className="form-check-input" type="checkbox" value={this.state.currently_working_edit} checked={this.state.currently_working_edit} id="currently_working_edit" />
                                                            <label className="form-check-label m-0 d-flex align-items-center" for="currently_working_edit">
                                                                Currently Working
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.currently_working_edit === false &&
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputCity">Ending Date</label>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <select value={this.state.end_month_edit} className="form-control" name="end_month_edit" onChange={this.handleChangeEdit}>
                                                                <option value="">Month</option>
                                                                <option value="1">January</option>
                                                                <option value="2">February</option>
                                                                <option value="3">March</option>
                                                                <option value="4">April</option>
                                                                <option value="5">May</option>
                                                                <option value="6">June</option>
                                                                <option value="7">July</option>
                                                                <option value="8">August</option>
                                                                <option value="9">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select className="form-control" name="end_year_edit" value={this.state.end_year_edit} onChange={this.handleChangeEdit}>
                                                                <option value="">Year</option>
                                                                {this.getDropList()}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="form-group col-md-12">
                                                <label htmlFor="">Description</label>
                                                <textarea row="5" onChange={this.handleChangeEdit} type="text" className="form-control" name="description_edit" placeholder="e.g. Bachelor of Engineering">{this.state.description_edit}</textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="">Job Title <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="jobtitle" placeholder="e.g. Python Developer" value={this.state.jobtitle} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4">Company <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="company" placeholder="e.g. Apple Inc" value={this.state.company} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="inputAddress">Location <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="location" placeholder="e.g. NY City, USA" value={this.state.location} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Start Date <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="start_month" value={this.state.start_month}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="start_year" value={this.state.start_year}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-12">
                                                        <div className="form-check mt-3 pl-0">
                                                            <input onChange={this.handleChecked} className="form-check-input" type="checkbox" value={this.state.currently_working} checked={this.state.currently_working} id="currently_working" />
                                                            <label className="form-check-label m-0 d-flex align-items-center" for="currently_working">
                                                                Currently Working
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.currently_working === false &&
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputCity">Ending Date</label>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <select onChange={this.handleChange} className="form-control" name="end_month" value={this.state.end_month}>
                                                                <option value="">Month</option>
                                                                <option value="1">January</option>
                                                                <option value="2">February</option>
                                                                <option value="3">March</option>
                                                                <option value="4">April</option>
                                                                <option value="5">May</option>
                                                                <option value="6">June</option>
                                                                <option value="7">July</option>
                                                                <option value="8">August</option>
                                                                <option value="9">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select onChange={this.handleChange} className="form-control" name="end_year" value={this.state.end_year}>
                                                                <option value="">Year</option>
                                                                {this.getDropList()}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-group col-md-12">
                                                <label htmlFor="">Description</label>
                                                <textarea row="5" onChange={this.handleChange} type="text" className="form-control" name="description" placeholder="Write some summary of your activities there">{this.state.description}</textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(Employments);