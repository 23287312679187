import React, { Component } from 'react';
import { removeLoaders, getResumeidFromSession, updateAvatarInSession, getIsEmailVerifiedFromSession } from './CommonFunctions';
import { getAvatar, isLoggedIn, getStatusShareResume, getProductList, updateDashboardTab, getIsSubscribed } from '../actions/CommonActions';
import { updateViewResumeID } from '../actions/ViewResumeActions';
import axios from 'axios';
import qs from 'querystring';
import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';
import { 
    IconFileAlt,
    IconShareDark,
    IconDownloadDark,
    IconExchangeSolid,
    IconCv,
    IconCoverletter,
    IconPencilDark,
    IconEllipsisHDark,
    IconInbox,
    IconPlusGrey,
    IconCheckBgSmall,
    IconPlusCircleWhite,
    IconTrashSolid,
    IconPlusWhite
 } from '../icon/index';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import Footer from './Footer';
import { Line, Circle } from 'rc-progress';
import ReactTooltip from 'react-tooltip';
import ResumeShareModal from './ResumeShareModal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import MetaTags from 'react-meta-tags';
import SupportFloating from './../Button/SupportFloating';
import ThumbnaiListlLoader from '../ThumbnaiListlLoader';
import SpinnerLoader from '../../src/Loader/SpinnerLoader';
import CircleLoader from '../../src/Loader/CircleLoader';
import { event } from 'react-ga';

class Dashboard extends Component {
    state = {
        preview_resume: false,
        data: '',
        numPages: null,
        pageNumber: 1,
        print_preview: false,
        preview_type: '',
        profile: '',
        resume_share: '',
        share_status: '',
        share_url: '',
        resumeid: '',
        verified: '',
        coverletterid: '',
        resume_list: null,
        coverletter_list: null,
        resume_title_single_edit: false,
        resume_title_edit: '',
        resume_id_edit: '',
        coverletter_title_single_edit: false,
        coverletter_title_edit: '',
        coverletter_id_edit: '',
        spinnerLoader: true,
        getProductListCalling: false,
        getIsSubscribedCalling: false,
        circleLoaderRRS: false,
        // is_subscribed: null,
        // is_subscribed_validity: null,
    };

    constructor() {
        super();
        this.inputRef = React.createRef();
      }

    componentDidMount() {
        removeLoaders();
        // this.props.isLoggedIn();
        // console.log(this.props.user_credentials.data);
        // this.props.user_credentials.data.coverletterid
        this.props.getProductList();
        this.props.getIsSubscribed();
        // localStorage.getItem("user") !== null &&
            
        // console.log(this.props.user_credentials.data.uid);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.resume_list)
        ReactTooltip.rebuild();

        if(prevProps.dashboard_tab_active === 2 && this.state.navtabActive !== 2){
            this.setState({
                navtabActive: this.props.dashboard_tab_active
            })
        }

        // if(prevState.coverletterid === ''){
        //     this.setState({
        //         coverletterid: this.props.user_credentials.data.coverletterid
        //     })
        // }
        if(prevState.verified === ''){
            this.setState({
                verified: this.props.user_credentials.data.verified
            })
        }
        // Getting resume and coverletter list
        if(prevState.resume_list === null && this.props.resume_list !== undefined && this.props.resume_list !== '' || prevState.resume_list === ''){
            this.setState({
                resume_list: this.props.resume_list,
                spinnerLoader: false
            })
        }
        if(prevState.coverletter_list === null && this.props.coverletter_list !== undefined && this.props.coverletter_list !== '' || prevState.coverletter_list === ''){
            this.setState({
                coverletter_list: this.props.coverletter_list,
                spinnerLoader: false
            })
        }

        if(prevProps.user_credentials.data.uid !== undefined && prevState.getProductListCalling === false){
            this.props.getProductList();
            this.setState({
                getProductListCalling: true
            })
        }

        if(prevProps.user_credentials.data.uid !== undefined && prevState.getIsSubscribedCalling === false){
            this.props.getIsSubscribed();
            this.setState({
                getIsSubscribedCalling: true
            })
        }
        // console.log(this.props.user_credentials.data)
        // console.log(this.props.product_list.resume_list)
    }

    // componentWillUnmount Memory leak issue

    // componentWillUnmount() {
    //     this._isMounted = false;
    //   }

    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleRename = (type) => {
        // console.log(type)
        this.setState({
            circleLoaderRRS: true
        })
        const data = {
            resumeid: this.state.resume_id_edit,
            resume_name: this.state.resume_title_edit
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/GetResumeName.php', qs.stringify(data), config)
        .then(res => res.data.data[0].resume[0])
        .then(res => {
            // Getting the index number of edit item
            const index = this.state.resume_list.map(resume => resume.resumeid === data.resumeid)
                const indices = index.reduce(
                (out, bool, index) => bool ? out.concat(index) : out,
                []
            )
            // console.log(indices)
            let resumes = [...this.state.resume_list];
            let resume = { ...resumes[indices] };
            resume = res;
            resumes[indices] = resume;

            // console.log(this.state.resume_list)
            this.setState({
                resume_title_single_edit: false,
                resume_list: resumes,
                circleLoaderRRS: false
            })
        })
        
    }

    handleRenameCoverletter = (type) => {
        // console.log(type)
        this.setState({
            circleLoaderRRS: true
        })
        const data = {
            coverid: this.state.coverletter_id_edit,
            covertitle: this.state.coverletter_title_edit
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/GetCoverLetterName.php', qs.stringify(data), config)
        .then(res => res.data.data[0].coverletter[0])
        .then(res => {
            // Getting the index number of edit item
            const index = this.state.coverletter_list.map(coverletter => coverletter.coverid === data.coverid)
                const indices = index.reduce(
                (out, bool, index) => bool ? out.concat(index) : out,
                []
            )
            // console.log(indices)
            let coverletters = [...this.state.coverletter_list];
            let coverletter = { ...coverletters[indices] };
            coverletter = res;
            coverletters[indices] = coverletter;

            // console.log(this.state.coverletter_list)
            this.setState({
                coverletter_title_single_edit: false,
                coverletter_list: coverletters,
                circleLoaderRRS: false
            })
        })
        
    }


    handleEditResumeTitle = (resume_title_edit, resume_id) => {
        // console.log(resume_title_edit, resume_id)
        this.setState({
            ...this.state,
            resume_title_single_edit: true,
            resume_title_edit,
            resume_id_edit: resume_id
        }, () => this.inputRef.current.focus())
    }

    handleEditCoverletterTitle = (coverletter_title_edit, coverletter_id) => {
        // console.log(coverletter_title_edit, coverletter_id)
        this.setState({
            ...this.state,
            coverletter_title_single_edit: true,
            coverletter_title_edit,
            coverletter_id_edit: coverletter_id
        }, () => this.inputRef.current.focus())
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };
    
    goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));


    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled',
            isDisabledUpload: 'disabled',
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled',
            isDisabledUpload: 'disabled'
        })
    }

    handlePreviewResume= (e) => {
        e.preventDefault();
        this.setState({
            preview_resume: true,
            preview_type: 'preview_resume',
        })
        
    }

    handlePrintResume = (e) => {
        e.preventDefault();
        this.setState({
            preview_resume: true,
            preview_type: 'preview_print',
        })
    }

    handlePreviewType = (e) => {
        e.preventDefault();
        this.setState({
            print_preview: true
        })
    }

    handleNavTabs = (type) => {
        this.props.updateDashboardTab(type)
    }

    handleDelete = (type, id) => {
        type === 'resume' &&
        axios.get('https://resumion.com/apiv2/Deleteitem.php?id='+id+'&type=resume')
        const resume_list = this.state.resume_list.filter(resume => resume.resumeid !== id);
        this.setState({ resume_list: resume_list });

        type === 'coverletter' &&
        axios.get('https://resumion.com/apiv2/Deleteitem.php?id='+id+'&type=cover')
        const coverletter_list = this.state.coverletter_list.filter(coverletter => coverletter.coverid !== id);
        this.setState({ coverletter_list: coverletter_list });
    }

    handleResumeIdDV = (resumeid) => {
        this.props.updateViewResumeID(resumeid)
    }
    
    render() {
        this.handlePreviewResume = this.handlePreviewResume.bind(this);
        this.handlePrintResume = this.handlePrintResume.bind(this);
        this.handlePreviewType = this.handlePreviewType.bind(this);
        // console.log(this.state)
        // console.log(this.props)

        // console.log(this.props.user_credentials)

        // if (this.props.user_credentials.data !== undefined) {
        //     var { first_name, last_name } = this.props.user_credentials.data
        // }

        const success_redux = this.props.user_credentials.success

        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }

        // console.log(this.props.details_view.objective)
        // console.log(success_redux, session_success)

        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }
        // console.log(this.props.user_credentials.data.first_name)
        // console.log(this.props)
        // console.log(this.state)
        // console.log(this.state.profile.needToAdd)
        // console.log(this.state.getProductListCalling)
        // console.log(this.state.resume_list)
        // console.log(this.props.resume_list)
        if (this.state.resume_list !== null && this.state.resume_list !== '' ) {
            var resume_list_all = this.state.resume_list.map(resume => {
                return (
                    <div className="col-md-3 mb-3" key={resume.resumeid}>
                        <div className="d-md-flex align-items-stretch mb-4">
                            <div className="card col-12 bg-transparent p-0 border-0 rc">
                                <div className="card-body p-0 text-center bg-transparent">
                                    {/* <Link onClick={()=>this.handleResumeIdDV(resume.resumeid)} to={`/resumes/${resume.resumeid}`}> */}
                                    <Link to={`/resumes/${resume.resumeid}/${resume.templateid}`}>
                                        <img className="img-fluid rounded-lg w-100" src={`https://resumion.com/images/${resume.thumbnail}`} alt={resume.thumbnail}/>
                                    </Link>
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0 text-center">
                                    <div className="dashboard-btn-group mt-2">
                                        <ReactTooltip/>
                                        <form onSubmit={(event) => {event.preventDefault(); this.handleRename('resume')}}>
                                            <div className='rc-meta-info pt-2 pb-2'>
                                                {this.state.resume_title_single_edit === true && (this.state.resume_id_edit === resume.resumeid) ? 
                                                    <>
                                                        <input ref={this.inputRef} required onChange={this.handleChangeEdit} type="text" className="form-control bottom-line border-0 p-0 bg-transparent text-center" name="resume_title_edit" placeholder="Type a resume name" value={this.state.resume_title_edit} />
                                                        
                                                        {this.state.resume_title_edit !== '' &&
                                                            <button type='button' className='btn p-0 btn-floating' onClick={()=>this.handleRename('resume', resume.resumeid)}>
                                                                {this.state.circleLoaderRRS === false ?
                                                                    <img src={IconCheckBgSmall} height="24" alt='submit'/>
                                                                    :
                                                                    <CircleLoader />
                                                                }
                                                            </button>
                                                        }
                                                    </>
                                                    :
                                                    <h5 className='mb-0 title d-flex align-items-center justify-content-center' onClick={()=> this.handleEditResumeTitle(resume.resumetitle, resume.resumeid)}>{resume.resumetitle}</h5>
                                                }
                                                {/* <p className='small mb-0 u-date'>Updated 24 Aug 2021, 22:14</p> */}
                                            </div>
                                        </form>

                                        <Link className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Preview" to={`/preview-resume/${resume.resumeid}/${resume.templateid}`}><img src={IconFileAlt} height="24" alt='preview resume'/></Link>
                                        <Link className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Change Template" to={`/change-rc/resume/${resume.resumeid}`}><img src={IconExchangeSolid} height="24" alt='change template'/></Link>
                                        <Link className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Edit" to={`/resumes/${resume.resumeid}/${resume.templateid}`}><img src={IconPencilDark} height="24" alt='edit'/></Link>
                                        
                                        <div className="dropdown d-inline">
                                            <button className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="More" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={IconEllipsisHDark} height="24" alt='more' /></button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <Link className="dropdown-item" to={`share-resume/${resume.resumeid}`}><img src={IconShareDark} height="24" alt='share'/> Share</Link>
                                                <a className="dropdown-item" rel="noopener noreferrer" target="_blank" href={`https://resumion.com/apiv2/DownloadResume.php?resumeid=${resume.resumeid}&templateid=${resume.templateid}&actiontype=download`}><img src={IconDownloadDark} height="24" alt='download'/> Download</a>
                                                {/* {console.log(this.state.resume_list.length)} */}
                                                {this.state.resume_list.length > 1 &&
                                                    <button type='button' onClick={()=>this.handleDelete('resume', resume.resumeid)} className="dropdown-item"><img src={IconTrashSolid} height="24" alt='delete'/> Delete</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        if (this.state.coverletter_list !== null && this.state.coverletter_list !== '') {
            var coverletter_list_all = this.state.coverletter_list.map(coverletter => {
                return (
                    <div className="col-md-3 mb-3" key={coverletter.coverid}>
                        <div className="d-md-flex align-items-stretch mb-4">
                            <div className="card col-12 bg-transparent p-0 border-0 rc">
                                <div className="card-body p-0 text-center bg-transparent">
                                    <Link to={`/cover-letters/${coverletter.coverid}/${coverletter.templateid}`}>
                                        <img className="img-fluid rounded-lg w-100" src={`https://resumion.com/images/${coverletter.thumbnail}`} alt={coverletter.thumbnail}/>
                                    </Link>
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0 text-center">
                                    <div className="dashboard-btn-group mt-2">
                                        <ReactTooltip/>
                                        <form onSubmit={(event) => {event.preventDefault(); this.handleRenameCoverletter('coverletter')}}>
                                            <div className='rc-meta-info pt-2 pb-2'>
                                                {/* {console.log('resume_id_edit: '+this.state.resume_id_edit, 'resume.resumeid: '+resume.resumeid)} */}
                                                {this.state.coverletter_title_single_edit === true && (this.state.coverletter_id_edit === coverletter.coverid) ? 
                                                    <>
                                                        <input ref={this.inputRef} required onChange={this.handleChangeEdit} type="text" className="form-control bottom-line border-0 p-0 bg-transparent text-center" name="coverletter_title_edit" placeholder="Type a cover letter name" value={this.state.coverletter_title_edit} />
                                                        
                                                        {this.state.coverletter_title_edit !== '' &&
                                                            <button type='button' className='btn p-0 btn-floating' onClick={()=>this.handleRenameCoverletter('coverletter', coverletter.coverid)}>
                                                                {this.state.circleLoaderRRS === false ?
                                                                    <img src={IconCheckBgSmall} height="24" alt='submit'/>
                                                                    :
                                                                    <CircleLoader />
                                                                }
                                                            </button>
                                                        }
                                                    </>
                                                    :
                                                    <h5 className='mb-0 title d-flex align-items-center justify-content-center' onClick={()=> this.handleEditCoverletterTitle(coverletter.covertitle, coverletter.coverid)}>{coverletter.covertitle}</h5>
                                                }
                                                {/* <p className='small mb-0 u-date'>Updated 24 Aug 2021, 22:14</p> */}
                                            </div>
                                        </form>



                                        <Link className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Preview" to={`/preview-cover-letter/${coverletter.coverid}/${coverletter.templateid}`}><img src={IconFileAlt} height="24" alt='preview cover letter'/></Link>
                                        <Link className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Change Template" to={`/change-rc/coverletter/${coverletter.coverid}`}><img src={IconExchangeSolid} height="24" alt='change template'/></Link>
                                        <Link className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Edit" to={`/cover-letters/${coverletter.coverid}/${coverletter.templateid}`}><img src={IconPencilDark} height="24" alt='edit'/></Link>
                                        
                                        <div className="dropdown d-inline">
                                            <button className="btn btn-outline-dark-circle p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="More" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={IconEllipsisHDark} height="24" alt='more' /></button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" rel="noopener noreferrer" target="_blank" href={"https://resumion.com/apiv2/DownloadCoverLetter.php?coverletterid=" + coverletter.coverid + "&templateid=1&actiontype=download"}><img src={IconDownloadDark} height="24" alt='download'/> Download</a>
                                                {this.state.coverletter_list.length > 1 &&
                                                    <button type='button' onClick={()=>this.handleDelete('coverletter', coverletter.coverid)} className="dropdown-item"><img src={IconTrashSolid} height="24" alt='delete'/> Delete</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        

        return (
            <React.Fragment>
                <MetaTags>
                    <title>Dashboard - Resumion</title>
                    <meta name="description" content="Create your resume within a few mins with free online resume builder. Let's create, download, share, print different types of stylish resume templates." />
                    <meta property="og:title" content="Free Online Resume Builder - Resumion" />
                </MetaTags>
                {/* {this.state.preview_resume &&
                    <PreviewResume handleBack={this.handleBack} data={this.state.data} previewType={this.state.preview_type}/>
                } */}
                <div className="page-title text-center mb-4 mt-5">
                    <h3 className="text-center">Dashboard</h3>
                    <p className="text-secondary">
                        Manage your resumes and cover letters
                    </p>
                </div>
                <div className="container mb-5">
                    {this.state.verified === "0" &&
                        <div className="alert alert-info mb-5 border-0 p-3">
                            <div className="d-flex align-items-center">
                                <div>
                                    <img src={IconInbox} alt="email" height="46"/>
                                </div>
                                <div className="flex-grow-1 ml-3">
                                    <h5 className="mb-1">Verify your email address</h5>
                                    <p className="m-0 small">Welcome to the world of Resumion. Please check your email to verify your account.</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-12 nav-container-dashboard mb-5'>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button type='button' className={`nav-link ${this.props.dashboard_tab_active === 1 && 'active'}`} onClick={()=>this.handleNavTabs(1)}>My Resume</button>
                                </li>
                                <li className="nav-item">
                                    <button type='button' className={`nav-link ${this.props.dashboard_tab_active === 2 && 'active'}`} onClick={()=>this.handleNavTabs(2)}>Cover Letter</button>
                                </li>
                            </ul>
                            <Link to={`${this.props.is_subscribed !== null && this.props.is_subscribed === 1 ? this.props.dashboard_tab_active === 1 ? '/create-rc#resume' : '/create-rc#coverletter':'/premium'}`} className='btn btn-primary btn-sm btn-absolute'>
                                <img className='mr-1 align-text-bottom' src={IconPlusCircleWhite} height="16" alt='icon'/> New {`${this.props.dashboard_tab_active === 1 ? 'Resume' : 'Cover Letter'}`}
                            </Link>
                        </div>
                    </div>
                    
                    {this.state.spinnerLoader === true ? 
                    <SpinnerLoader />
                    :
                    this.props.dashboard_tab_active === 1 &&
                    <div className="row">
                        {resume_list_all}
                    </div>
                    }
                    
                    {this.props.dashboard_tab_active === 2 &&
                    <div className='row'>
                        {coverletter_list_all}
                    </div>
                    }
                </div>
                <SupportFloating />
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    avatar: state.COM_REDU.avatar,
    status_share_resume: state.COM_REDU.status_share_resume,
    resume_list: state.COM_REDU.resume_list,
    coverletter_list: state.COM_REDU.coverletter_list,
    is_subscribed: state.COM_REDU.is_subscribed,
    is_subscribed_validity: state.COM_REDU.is_subscribed_validity,
    dashboard_tab_active: state.COM_REDU.dashboard_tab_active,
})

export default connect(mapStateToProps, { getAvatar, isLoggedIn, getStatusShareResume, getProductList, updateViewResumeID, updateDashboardTab, getIsSubscribed })(Dashboard);
