import React, { Component } from 'react';
import axios from 'axios';
import LayoutLoader from '../../LayoutLoader';
import qs from 'querystring';
import { connect } from 'react-redux';
import BtnLoader from '../../BtnLoader';
import { IconFileText } from '../../icon/index';
import { removeLoaders, isNull } from '../CommonFunctions';
import { toast } from 'react-toastify';
import EmptyState from '../../components/EmptyState';
import { Link, Redirect } from "react-router-dom";
import Footer from '../Footer';
import MetaTags from 'react-meta-tags';
import TextareaAutosize from 'react-textarea-autosize';
import { 
    IconEdit, 
    IconFileAlt,
    IconDownloadDark
} from '../../icon/index';

class CoverLetter extends Component {
    state = {
        name: '',
        jobtitle: '',
        address: '',
        email: '',
        phone: '',
        company: '',
        hiringmanager: '',
        letterdetails: '',
        coverlettertitle: '',
        name_edit: '',
        jobtitle_edit: '',
        address_edit: '',
        email_edit: '',
        phone_edit: '',
        company_edit: '',
        hiringmanager_edit: '',
        letterdetails_edit: '',
        coverlettertitle_edit: '',
        layout_loader: true,
        view_coverletter: false,
        edit_coverletter: false,
        add_coverletter: false,
        coverid: '',
        id: '2',
        success: '',
        submit_type: ''
    }
    componentDidMount() {
        removeLoaders();
        axios.get('https://resumion.com/apiv2/GetCoverLetter.php?coverid='+this.props.match.params.coverid)
        .then(res => res.data)
        .then(res => {
            if(res.success === 0){
                this.setState({
                    success: res.success,
                    layout_loader: false,
                })
            }
            if(res.success === 1){
                this.setState({
                    success: res.success,
                    name: res.data[0].coverletter[0].name,
                    jobtitle: res.data[0].coverletter[0].jobtitle,
                    address: res.data[0].coverletter[0].address,
                    email: res.data[0].coverletter[0].email,
                    phone: res.data[0].coverletter[0].phone,
                    company: res.data[0].coverletter[0].company,
                    hiringmanager: res.data[0].coverletter[0].hiringmanager,
                    letterdetails: res.data[0].coverletter[0].letterdetails,
                    coverlettertitle: res.data[0].coverletter[0].coverlettertitle,
                    layout_loader: false,
                    view_coverletter: true,
                })
            }
        })
        .catch(error => { 
            // your error handling goes here
            console.log(error)
        })
    }
    
    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: ''
        })
    }

    handleEdit = (e) => {
        e.preventDefault();
        this.setState({
            view_coverletter: false,
            edit_coverletter: true,
            submit_type: 'Update'
        })
        this.setState({
            name_edit: this.state.name,
            jobtitle_edit: this.state.jobtitle,
            email_edit: this.state.email,
            phone_edit: this.state.phone,
            address_edit: this.state.address,
            company_edit: this.state.company,
            hiringmanager_edit: this.state.hiringmanager,
            letterdetails_edit: this.state.letterdetails,
            coverlettertitle_edit: this.state.coverlettertitle
        })
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view_coverletter: true,
            edit_coverletter: false,
        })
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            coverid: this.props.match.params.coverid,
            name: this.state.name_edit,
            jobtitle: this.state.jobtitle_edit,
            email: this.state.email_edit,
            phone: this.state.phone_edit,
            address: this.state.address_edit,
            company: this.state.company_edit,
            hiringmanager: this.state.hiringmanager_edit,
            letterdetails: this.state.letterdetails_edit
        }
        const config = {
            "Content-Type": "application/json"
        }
        if(this.state.submit_type === "Add"){
            axios.post('https://resumion.com/apiv2/GetCoverLetter.php?', qs.stringify(data), config)
            // .then(res => console.log(res))
            .then(() => this.notify())
            .then(() => this.endBtnLoader())
            .then(() => {
                this.setState({
                    name: this.state.name_edit,
                    jobtitle: this.state.jobtitle_edit,
                    email: this.state.email_edit,
                    phone: this.state.phone_edit,
                    address: this.state.address_edit,
                    company: this.state.company_edit,
                    hiringmanager: this.state.hiringmanager_edit,
                    letterdetails: this.state.letterdetails_edit,
                    view_coverletter: true,
                    edit_coverletter: false,
                    success: 1,
                })
            })
        }
        if(this.state.submit_type === "Update"){
            axios.post('https://resumion.com/apiv2/GetCoverLetter.php?', qs.stringify(data), config)
            // .then(res => console.log(res))
            .then(() => this.notify())
            .then(() => this.endBtnLoader())
            .then(() => {
                this.setState({
                    name: this.state.name_edit,
                    jobtitle: this.state.jobtitle_edit,
                    email: this.state.email_edit,
                    phone: this.state.phone_edit,
                    address: this.state.address_edit,
                    company: this.state.company_edit,
                    hiringmanager: this.state.hiringmanager_edit,
                    letterdetails: this.state.letterdetails_edit,
                    coverlettertitle: this.state.coverlettertitle_edit,
                    view_coverletter: true,
                    edit_coverletter: false,
                })
            })
        }
        
    }

    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleAdd = (e) => {
        e.preventDefault();
        this.setState({
            view_coverletter: false,
            edit_coverletter: true,
            submit_type: 'Add'
        })
    }
    
    render() { 
        const {
            name,
            jobtitle,
            address,
            email,
            phone,
            company,
            hiringmanager,
            letterdetails,
            coverlettertitle
        } = this.state

        this.handleAdd = this.handleAdd.bind(this)
        // console.log(this.state)
        // console.log(this.props)

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }
        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }

        // console.log(this.props.product_list.coverletter_list)

        return (
            <div className="">
                <div className="wrapper">
                    <MetaTags>
                        <title>Cover Letter - Resumion</title>
                        <meta property="og:title" content="Cover Letter - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-lg-9">
                            <div className="card personal-details mb-4 mt-4" >
                                {this.state.layout_loader === true ? (
                                    <div className="col-sm-8 m-5">
                                        <LayoutLoader />
                                    </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className="card-title mb-4">
                                                <div className="row">
                                                    <div className="col-md-8 col-sm-10">
                                                        <h5 className="text-primary">
                                                            <img src={IconFileText} alt="file" height="24" /> Cover Letter
                                                        </h5>
                                                    </div>
                                                    {this.state.success === 1 &&
                                                        <div className="ml-auto col-md-2 col-sm-12 text-md-right">
                                                            <button onClick={this.handleEdit.bind(this)} className="btn btn-link-edit p-0 mr-2"><img src={IconEdit} alt="edit" height="20"/></button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                {this.state.success === 0 && this.state.edit_coverletter === false &&
                                                    <div className="text-center">
                                                        <EmptyState />
                                                        <button onClick={this.handleAdd} className="btn btn-primary"><i className="fa fa-plus-circle"></i>&nbsp;Add New</button>
                                                    </div>
                                                }
                                                {this.state.view_coverletter && this.state.success === 1 &&
                                                    <div>
                                                        <div className="user-general-info">
                                                            <h6>Personal Details</h6>
                                                            <div className="row">
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="">Full Name</label>
                                                                    <div className="phone-number">{isNull(name)}</div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="">Job Title</label>
                                                                    <div>{isNull(jobtitle)}</div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="">Email</label>
                                                                    <div>{isNull(email)}</div>
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="">Phone Number</label>
                                                                    <div>{isNull(phone)}</div>
                                                                </div>
                                                                <div className="col-md-12 mt-3">
                                                                    <label htmlFor="">Address</label>
                                                                    <div>{isNull(address)}</div>
                                                                </div>
                                                                
                                                                <div className="col-12">
                                                                    <hr className="w-100 mt-3 mb-3" />
                                                                </div>

                                                                <div className="col-md-12 mt-3">
                                                                    <h6>Employer Details</h6>
                                                                </div>

                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="">Company Name</label>
                                                                    <div className="phone-number">{isNull(company)}</div>
                                                                </div>
                                                                
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="">Hiring Manager Name</label>
                                                                    <div>{isNull(hiringmanager)}</div>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                    <label htmlFor="">Letter Details</label>
                                                                    <div>
                                                                        {letterdetails !== '' &&
                                                                            <TextareaAutosize className="form-control view-textarea p-0 border-0 bg-white" defaultValue={letterdetails} readOnly="readonly"/>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.edit_coverletter &&
                                                    <form className="personal-details-edit" onSubmit={this.handleSubmit}>
                                                        <h6 className="mb-4">Personal Details</h6>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="name_edit">Full Name</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="name_edit" placeholder="e.g. John Doe" value={this.state.name_edit} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="jobtitle_edit">Job Title</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="jobtitle_edit" placeholder="e.g. Python Developer" value={this.state.jobtitle_edit} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="email_edit">Email</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="email_edit" placeholder="e.g. name@email.com" value={this.state.email_edit} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="phone_edit">Phone Number</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="phone_edit" placeholder="e.g. 601-370-5626" value={this.state.phone_edit} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-12">
                                                                <label htmlFor="address_edit">Address</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="address_edit" placeholder="e.g. 10011 NY City" value={this.state.address_edit} />
                                                            </div>
                                                        </div>

                                                        <h6 className="mb-4 mt-3">Employer Details</h6>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="company_edit">Company Name</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="company_edit" placeholder="e.g. Apple" value={this.state.company_edit} />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="hiringmanager_edit">Hiring Manager Name</label>
                                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="hiringmanager_edit" placeholder="e.g. Zim Mir" value={this.state.hiringmanager_edit} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-12">
                                                                <label htmlFor="letterdetails_edit">Letter Details</label>
                                                                <textarea onChange={this.handleChangeEdit} name="letterdetails_edit" className="form-control" cols="30" rows="5" placeholder="e.g. I am writing in response to the position for..." defaultValue={this.state.letterdetails_edit}></textarea>
                                                            </div>
                                                        </div>
                                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                                        </button>
                                                        <button onClick={this.handleCancel.bind(this)} className="btn btn-outline-dark">Cancel</button>
                                                    </form>
                                                }
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-sm-4 col-lg-3">
                            <div className='card mb-4 mt-4'>
                                <div className='card-body'>
                                    <Link type="button" to={`/preview-cover-letter/${this.props.match.params.coverid}/${this.props.match.params.templateid}`} className="btn btn-light btn-sm btn-block"><img src={IconFileAlt} height="20" alt='preview' /> Preview</Link>
                                    <a rel="noopener noreferrer" target="_blank" href={`https://resumion.com/apiv2/DownloadCoverLetter.php?coverletterid=${this.props.match.params.coverid}&templateid=${this.props.match.params.templateid}&actiontype=download`} className="btn btn-light btn-sm btn-block"><img src={IconDownloadDark} height="20" alt='download' /> Download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    // details_view_cl: state.VIEW_RES_REDU.details_view_cl ? state.VIEW_RES_REDU.details_view_cl : '',
})

export default connect(mapStateToProps, null)(CoverLetter);