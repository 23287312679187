import React, { Component } from 'react';
import { removeLoaders } from './CommonFunctions';
import Footer from './Footer';
import MetaTags from 'react-meta-tags';

class AboutUs extends Component {
    componentDidMount() {
        removeLoaders();
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>About Us - Resumion</title>
                        <meta property="og:title" content="About Us - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>

                <div className='container'>
                    <div className="page-title text-center mb-4 mt-5">
                        <h3 className="text-center">About Us</h3>
                    </div>
                    <div className='row'>

                        <div className="d-flex justify-content-center mb-5">
                            <div className="col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <p>
                                        The idea was introduced in 2018 by a couple of enthusiastic guys who found it really hard in their daily office routine works to experiment with various technologies with their own ideas. Starting with some simple trials projects, they happened to create the site in 2019 called resumion.com
                                        </p>
                                        <p>
                                        Creating an online resume is not a new one and the site is relatively new in the race of its kind. Yet it’s easier to be used as an online resume and cover letter builder helping  job hunters, professionals, and students become their own best candidate during their job search.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default AboutUs;