import React, { Component } from 'react';
import SpinnerOval from '../../SpinnerOval';
import { getMonth } from '../CommonFunctions';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import BtnLoader from '../../BtnLoader';
import { IconGraducationCap, IconTrash, IconPlusCircleWhite } from '../../icon/index';
import EmptyState from '../../components/EmptyState';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class Education extends Component {
    state = {
        resumeid: null,
        education: null,
        view: true,
        edit: false,
        add_new: false,

        degree_name: [],
        school: [],
        city: [],
        start_month: [],
        start_year: [],

        id_edit: null,
        school_edit: null,
        degree_name_edit: null,
        city_edit: null,
        level_edit: null,
        start_year_edit: null,
        end_year_edit: null,
        start_month_edit: null,
        end_month_edit: null,
        description_edit: null,

        layout_loader: true,
        isDisabled: 'disabled',
        btn_loader: false
    }
    componentDidMount() {
        // console.log(getResumeidFromSession())
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.layout_loader === true) {
            this.setState({
                ...this.state,
                layout_loader: false
            })
        }
        if (prevState.education === null && this.props.education !== null) {
            if (this.props.education !== undefined) {
                this.setState({
                    ...this.state,
                    education: this.props.education
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    notifySuccess = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    getDropList = () => {
        const year = 1950;
        return (
            Array.from(new Array(100), (v, i) =>
                <option key={i} value={year + i}>{year + i}</option>
            )
        );
    };
    // addSkill = (e) => {
    //     e.preventDefault();
    //     this.props.insertEmploymentForm();
    // }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsEdit())
    }
    checkMandatoryFields = () => {
        const degreeLength = this.state.degree_name.length;
        const schoolLength = this.state.school.length;
        const locationLength = this.state.city.length;
        const startMonthLength = this.state.start_month.length;
        const startYearLength = this.state.start_year.length;
        
        degreeLength !== 0 && schoolLength !== 0 && locationLength !== 0 && startMonthLength !== 0 && startYearLength !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }
    checkMandatoryFieldsEdit = () => {
        const degreeLength = this.state.degree_name_edit.length;
        const schoolLength = this.state.school_edit.length;
        const locationLength = this.state.city_edit.length;
        const startMonthLength = this.state.start_month_edit.length;
        const startYearLength = this.state.start_year_edit.length;
        // console.log(degreeLength)
        
        degreeLength !== 0 && schoolLength !== 0 && locationLength !== 0 && startMonthLength !== 0 && startYearLength !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }
    handleAddEducation = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true
        })
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            school: this.state.school,
            degree_name: this.state.degree_name,
            city: this.state.city,
            start_year: this.state.start_year,
            end_year: this.state.end_year,
            start_month: this.state.start_month,
            end_month: this.state.end_month,
            description: this.state.description
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddEducation.php', qs.stringify(data), config)
            .then(res => res.data.data[0].education[0])
            // .then(res => console.log(res))

            .then(res => this.setState({
                ...this.state,
                view: true,
                edit: false,
                add_new: false,


                school: '',
                degree_name: '',
                city: '',
                start_year: '',
                end_year: '',
                start_month: '',
                end_month: '',
                level: '',
                description: '',
                education: [...this.state.education, res]
            }))
            .then(() => this.notifySuccess())
            .then(() => this.endBtnLoader())
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            id: this.state.id_edit,
            school: this.state.school_edit,
            degree_name: this.state.degree_name_edit,
            city: this.state.city_edit,
            start_year: this.state.start_year_edit,
            end_year: this.state.end_year_edit,
            start_month: this.state.start_month_edit,
            end_month: this.state.end_month_edit,
            description: this.state.description_edit
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetEducation.php', qs.stringify(data), config)
            .then(res => res.data.data[0].education[0])
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.education.map(education => education.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let educations = [...this.state.education];
                let education = { ...educations[indices] };
                education = res;
                educations[indices] = education;
                this.setState({
                    view: true,
                    edit: false,
                    education: educations
                });
            })
            .then(() => this.notifySuccess())
            .then(() => this.endBtnLoader())
    }

    handleDelete = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'education'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    // .then(res => console.log(res.data))
                const education = this.state.education.filter(education => education.id !== id);
                this.setState({ education: education });
                this.notifyDelete();
            }
        })
    }

    handleEdit = (id) => {
        const educations = this.state.education.filter(education => education.id === id);
        this.setState({
            view: false,
            edit: true,
            degree_name_edit: educations[0].degree_name,
            id_edit: educations[0].id,
            school_edit: educations[0].school,
            city_edit: educations[0].city,
            start_month_edit: educations[0].start_month,
            start_year_edit: educations[0].start_year,
            end_month_edit: educations[0].end_month,
            end_year_edit: educations[0].end_year,
            description_edit: educations[0].description,
            isDisabled: ''
        })
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddEducation = this.handleAddEducation.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        // this.handleEdit = this.handleEdit.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);

        // console.log(this.state)
        // console.log(this.props)
        if (this.state.education !== null) {
            var educations = this.state.education.map((education, i, arr) => {
                return (
                    <React.Fragment key={education.id}>
                        <div className="row">
                            <div className="col-md-10">
                                <h5 className="degree-name mb-1">{education.degree_name}</h5>
                                <h6 className="degree-school mb-2">
                                    {education.school + ", " + education.city} 
                                    <span className="degree-duration mb-2">
                                        / 
                                        {education.start_month !== null && getMonth(education.start_month)}
                                        {education.start_year !== null && ", " + education.start_year}
                                        
                                        {education.end_month !== null && " - " + getMonth(education.end_month)}
                                        {education.end_year !== null && ", " + education.end_year}
                                        {/* {education.start_month + ", " + education.start_year + "-" + education.end_month + ", " + education.end_year} */}
                                    </span>
                                </h6>
                            </div>

                            <div className="ml-auto col-md-2 col-sm-12 text-md-right text-sm-left">
                                <button onClick={this.handleEdit.bind(this, education.id)} className="btn btn-link-edit p-0"> <img src={IconEdit} alt="edit" height="20"/></button>
                                <button onClick={this.handleDelete.bind(this, education.id)} className="btn btn-link-delete p-0 ml-2"> <img src={IconTrashSolid} alt="delete" height="20"/></button>
                            </div>
                            <div className="w-100"></div>
                            <div className="col">
                                {/* <p>{education.description}</p> */}
                                {education.description !== '' &&
                                    <TextareaAutosize className="form-control view-textarea p-0 border-0 bg-white" defaultValue={education.description} readOnly="readonly"/>
                                }
                                {/* <textarea className="form-control" defaultValue={education.description}></textarea> */}
                            </div>
                        </div>
                        {arr.length !== i + 1 &&
                            <hr className="mt-3 mb-3" />
                        }
                    </React.Fragment>
                )
            })
            // console.log(this.state.education.length)
        }
        return (
            <div className="card skills mb-4">

                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            {/* <MyEditor/> */}
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconGraducationCap} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">Educations</h5>
                                        <p className="text-muted mb-0">Add your academic degrees in order of latest completion and achievement in the description section.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view && this.state.education !== null && 
                                    <React.Fragment>
                                        {educations}
                                        {this.state.education.length === 0 &&
                                            <div className="text-center">
                                                <EmptyState />
                                                <button onClick={this.handleAddEducation} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                            </div>
                                        }
                                        {this.state.education.length !== 0 &&
                                            <button onClick={this.handleAddEducation} className="btn btn-primary mt-3"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="">Degree <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="degree_name_edit" placeholder="e.g. Bachelor of Engineering" value={this.state.degree_name_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4">School/ University <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="school_edit" placeholder="e.g. Brooklyn Latin School" value={this.state.school_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="inputAddress">Location <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="city_edit" placeholder="e.g. Babylon" value={this.state.city_edit} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Start Date <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="start_month_edit" onChange={this.handleChangeEdit} value={this.state.start_month_edit}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="start_year_edit" onChange={this.handleChangeEdit} value={this.state.start_year_edit}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Ending Date (or expected)</label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select value={this.state.end_month_edit} className="form-control" name="end_month_edit" onChange={this.handleChangeEdit}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="form-control" name="end_year_edit" value={this.state.end_year_edit} onChange={this.handleChangeEdit}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label htmlFor="description_edit">Description</label>
                                                <textarea row="5" onChange={this.handleChangeEdit} type="text" className="form-control" name="description_edit" placeholder="e.g. Bachelor of Engineering" defaultValue={this.state.description_edit}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="">Degree <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="degree_name" placeholder="e.g. Bachelor of Engineering" value={this.state.degree_name} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4">School/ University <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="school" placeholder="e.g. Brooklyn Latin School" value={this.state.school} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="inputAddress">Location <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="city" placeholder="e.g. Babylon" value={this.state.city} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Start Date <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="start_month" value={this.state.start_month}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="start_year" value={this.state.start_year}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputCity">Ending Date (or expected)</label>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="end_month" value={this.state.end_month}>
                                                            <option value="">Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select onChange={this.handleChange} className="form-control" name="end_year" value={this.state.end_year}>
                                                            <option value="">Year</option>
                                                            {this.getDropList()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="">Description</label>
                                                <textarea row="5" onChange={this.handleChange} type="text" className="form-control" name="description" placeholder="e.g. Bachelor of Engineering" defaultValue={this.state.description}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(Education);
