import React, { Component } from 'react';
import Switch from "react-switch";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    IconEnvelopeWhite,
    IconFacebookWhite,
    IconTwitterWhite,
    IconLinkedinWhite,
    IconAngleLeftWhite
 } from '../icon/index';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { removeLoaders } from './CommonFunctions';
import { connect } from 'react-redux';
import history from '../history';
import LayoutLoader from '../LayoutLoader';

class ResumeShareModal extends Component {
    state = {
        checked: false,
        value: '',
        copied: false,
        copy_text: 'Copy',
        resumeid: '',
        share_status: '',
        share_url: '',
    }
    handleChange(checked) {
        // this.setState({ checked });
        if(this.state.checked){
            this.setState({ 
                checked: false,
                share_status: "0"
            }, () => {
                axios.get('https://resumion.com/apiv2/GetUserPublicLink.php?resumeid=' + this.props.match.params.resumeid + '&templateid=1&active=0')
                // .then(res => console.log(res))
            })
        }
        if(this.state.checked === false){
            this.setState({ 
                checked: true,
                share_status: "1"
            }, () => {
                axios.get('https://resumion.com/apiv2/GetUserPublicLink.php?resumeid=' + this.props.match.params.resumeid + '&templateid=1&active=1')
                .then(res => {
                    this.setState({
                        share_url: res.data.sharelink
                    })
                })
            })
        }
    }
    handleShareURL = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            copied: false
        });
    }
    handleFocus = (e) => {
        e.target.select()
    }
    handleBack = (e) => {
        e.preventDefault();
        history.goBack();
    }
    componentDidMount() {
        let resumeid = this.props.match.params.resumeid
        this.setState({
            resumeid: resumeid,
        }, () => {
            axios.get('https://resumion.com/apiv2/GetUserPublicLink.php?resumeid=' +  +'&templateid=1')
            .then(res => res.data)
            .then(res => {
                this.setState({
                    share_status: res.active,
                    share_url: res.sharelink,
                })
            })
        })
        removeLoaders();
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.state.share_status === '1' && this.state.checked === false){
            this.setState({
                checked: true
            })
        }
        if(this.state.share_status === '0' && this.state.checked === true){
            this.setState({
                checked: false
            })
        }
    }

    
    render() { 
        this.handleChange = this.handleChange.bind(this);
        this.handleShareURL = this.handleShareURL.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBack = this.handleBack.bind(this);
        // console.log(this.props)
        // console.log(this.state)

        
        return (
        <React.Fragment>
            <div className="page-modal position-absolute w-100">
                <div className="d-flex action-panel justify-content-between align-items-center bg-dark p-2 position-fixed w-100">
                    <button onClick={this.handleBack} className="btn text-white btn-lg btn-back d-flex align-items-center"><img className="mr-3" src={IconAngleLeftWhite} alt="share" height="18"/>Back</button>
                </div>
                <div className="container page-body">

                    <div className="page-title text-center mt-5 mb-4">
                    </div>
                    <div className="d-flex justify-content-center mb-5">
                        <div className="col-sm-6 col-lg-8">

                            <div className="card">
                                <div className="card-body">
                                    {this.state.share_status === '' ? (
                                        <LayoutLoader/>                                        
                                    ) : (

                                    
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <h5 className="modal-title mb-3" id="shareModalLabel">Share your resume</h5>
                                            <p>
                                                Share your resume with anybody. Just copy the URL and share it directly to any media like Email, Text, Social Media.
                                            </p>
                                        <label className="d-flex align-items-center mt-3 mb-3">
                                            <Switch className="mr-2" onChange={this.handleChange} checked={this.state.checked} />
                                            <ReactTooltip/>
                                            {this.state.checked === true ? (
                                            <span className="font-weight-bold" data-place="top" data-effect="solid" data-tip="Click to turn off sharing" data-type="dark">Sharing is on</span>
                                            ): (<span className="font-weight-bold" data-place="top" data-effect="solid" data-tip="Click to turn on sharing" data-type="dark">Sharing is off</span>)}
                                        </label>

                                        {this.state.checked &&
                                            <React.Fragment>
                                            <div className="d-flex align-items-end mb-3">
                                                <div className="flex-grow-1">
                                                    <div className="form-group m-1">
                                                        <label className="font-weight-bold" htmlFor="copyLink">Copy Link</label>
                                                        <input onChange={this.handleShareURL} onFocus={this.handleFocus} type="text" className="form-control" id="copyLink" value={this.state.share_url}/>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <CopyToClipboard className="btn btn-primary m-1" onCopy={() => this.setState({copied: true, copy_text: 'Copied'}, ()=>{
                                                        setTimeout(() => {this.setState({ copy_text: 'Copy' })}, 1000);
                                                    })} text={this.state.share_url}>
                                                        <span>{this.state.copy_text}</span>
                                                    </CopyToClipboard>
                                                </div>
                                                <div className="">
                                                    <a className="btn btn-outline-dark m-1" rel="noopener noreferrer" target="_blank" href={this.state.share_url}>Open Link</a>
                                                </div>
                                            </div>

                                                <label className="font-weight-bold d-block" htmlFor="copyLink">Share</label>
                                            
                                                <div className="social-btn-group">
                                                    <ReactTooltip/>
                                                    <EmailShareButton url={this.state.share_url} children={
                                                    <button className="btn btn-danger p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Email" data-type="dark">
                                                        <img src={IconEnvelopeWhite} alt="Email" width="24"/>
                                                    </button>
                                                    } />
                                                    <FacebookShareButton url={this.state.share_url} children={
                                                        <button className="btn btn-facebook p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Share on Facebook" data-type="dark">
                                                            <img src={IconFacebookWhite} alt="Share on Facebook" height="24"/>
                                                        </button>
                                                    } />
                                                    <TwitterShareButton url={this.state.share_url} children={
                                                    <button className="btn btn-twitter p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Share on Twitter" data-type="dark">
                                                        <img src={IconTwitterWhite} alt="Share on Twitter" width="24"/>
                                                    </button>
                                                    } />
                                                    <LinkedinShareButton url={this.state.share_url} children={
                                                    <button className="btn btn-linkedin p-0 rounded-circle m-1" data-place="top" data-effect="solid" data-tip="Share on LinkedIn" data-type="dark">
                                                        <img src={IconLinkedinWhite} alt="Share on LinkedIn" height="24"/>
                                                    </button>
                                                    } />
                                                </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    // status_share_resume: state.COM_REDU.status_share_resume
    // status_share_resume: state.COM_REDU.status_share_resume ? state.COM_REDU.status_share_resume : ''
})

export default connect(mapStateToProps, null )(ResumeShareModal);