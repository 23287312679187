import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Home from './components/Home';
import RegForms from './components/RegForms';
import Navbar from './components/Navbar';
import Resume from './components/Resume';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import EmailVerification from './components/EmailVerification';
import ForgetPassword from './components/ForgetPassword';
import AccountSettings from './components/AccountSettings';
import ContactUs from './components/ContactUs';
import Pricing from './components/Pricing';
import Subscription from './components/Subscription';
import PaymentStatus from './components/Status/PaymentStatus';
import MakePayment from './components/Payment/MakePayment';
import PremiumFeatures from './components/Status/PremiumFeatures';
import CreateRC from './components/Create/CreateRC';
import ChangeRC from './components/Create/ChangeRC';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Gdpr from './components/Gdpr';
import AboutUs from './components/AboutUs';
import ResumeShare from './components/ResumeShare';
import ResumeShareModal from './components/ResumeShareModal';
import PreviewResume from './components/Preview/PreviewResume';
import PreviewCoverLetter from './components/Preview/PreviewCoverLetter';
import CoverLetter from './components/CoverLetter/CoverLetter';
import CookiesDialog from './components/CookiesDialog';
import NotFound from './components/Status/NotFound';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';

import history from './history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'react-router-scroll-top';

class App extends Component {
  state = {}

  render() {
    return (
      <div>
        <ToastContainer />
        <Provider store={store}>
          <Router history={history}>
            <ScrollToTop>
              <Navbar />
              <CookiesDialog/>
              <Switch>
                  <Route exact path='/' component={Home} />
                  <PublicRoute exact path='/builder' component={RegForms} />
                  <Route path='/dashboard' component={Dashboard} />
                  <Route path='/login' component={Login} />
                  <PrivateRoute exact path='/account-settings' component={AccountSettings} />
                  <Route path='/resumes/:resumeid/:templateid' component={Resume} />
                  <Route path='/cover-letters/:coverid/:templateid' component={CoverLetter} />
                  <Route path='/contact-us' component={ContactUs} />
                  <Route path='/pricing' component={Pricing} />
                  <Route path='/subscription' component={Subscription} />
                  <Route path='/payment-status' component={PaymentStatus} />
                  <Route path='/make-payment' component={MakePayment} />
                  <Route path='/premium' component={PremiumFeatures} />
                  <Route path='/create-rc' component={CreateRC} />
                  <Route path='/change-rc/:type/:id' component={ChangeRC} />
                  <Route path='/verifyemail' component={EmailVerification} />
                  <Route exact path='/forgetpassword' component={ForgetPassword} />
                  <Route exact path='/share' component={ResumeShare} />
                  <Route path='/share-resume/:resumeid' component={ResumeShareModal} />
                  <Route path='/terms-and-conditions' component={TermsAndConditions} />
                  <Route path='/privacy-policy' component={PrivacyPolicy} />
                  <Route path='/gdpr' component={Gdpr} />
                  <Route path='/about-us' component={AboutUs} />
                  <Route path='/preview-resume/:resumeid/:templateid' component={PreviewResume} />
                  {/* <Route exact path='/preview-resume/:print' component={PreviewResume} /> */}
                  <Route path='/preview-cover-letter/:coverid/:templateid' component={PreviewCoverLetter} />
                  <Route component={NotFound} />
              </Switch>
              </ScrollToTop>
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;