import { FETCH_VIEW_RESUME, UPDATE_VIEW_RESUME_ID, FETCH_VIEW_CL } from './type';
import axios from 'axios';

export const fetchViewResume = (resumeid) => dispatch => {
    console.log('fetchDetailsView called')
    axios.get('https://resumion.com/apiv2/ViewResume.php?resumeid=' + resumeid)
        .then(res => res.data.data[0])
        .then(details_view => {
            dispatch({
                type: FETCH_VIEW_RESUME,
                payload: details_view
            })
        })
}

export const fetchViewCoverLetter = (coverid) => dispatch => {
    console.log('fetchDetailsViewCL called')
    axios.get('https://resumion.com/apiv2/GetCoverLetter.php?coverletterid=' + coverid)
        .then(res => res.data.data[0])
        .then(details_view_cl => {
            dispatch({
                type: FETCH_VIEW_CL,
                payload: details_view_cl
            })
            console.log(details_view_cl)
        })
}

export const updateViewResumeID = (resumeid) => dispatch => {
    console.log('updateViewResumeID called')
    dispatch({
        type: UPDATE_VIEW_RESUME_ID,
        payload: resumeid
    })
}