import React, { Component } from 'react';
import { IconLifeRingSolidWhite } from '../icon/index';

class SupportFloating extends Component {
    render() {
        return (
            <a target="_blank" rel="noopener noreferrer" className="btn btn-danger btn-sm btn-floating floating-bottom-right" href="https://tickets.resumion.com/">
                <img src={IconLifeRingSolidWhite} alt="edit" height="20"/> Support Center
            </a>
        )
    }
}

export default SupportFloating;



