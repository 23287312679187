import React, { Component } from 'react';
import BtnLoader from '../BtnLoader';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserLogin, callLoader, retryLogin, isLoggedIn, getAvatar } from '../actions/CommonActions';
import { IconEnvelopeCircle, IconLockCheckCircle } from '../icon/index';
import { removeLoaders, getUseridFromSession } from './CommonFunctions';
import PageContentLoader from '../PageContentLoader';
import axios from 'axios';
import qs from 'querystring';

class ForgetPassword extends Component {
    state = {
        password: '',
        confirm_password: '',
        isDisabled: 'disabled',
        notMatched: false,
        changed_success: false,
    }
    componentDidMount() {
        this.props.isLoggedIn();
        // let userid = getUseridFromSession()
        removeLoaders();
        // const userid = this.props.match.params.userid;
        let userid = this.props.location.hash.slice(1);
        this.setState({
            userid: userid
        })
    }
    checkMandatoryFields = () => {
        { this.state.password.length !== 0 && this.state.confirm_password.length !== 0 && this.state.password === this.state.confirm_password? (
                this.setState({
                    isDisabled: ''
                })
            ) : (
                this.setState({
                    isDisabled: 'disabled'
                })
            )
        }
        this.checkMatched()
    }

    checkMatched = () => {
        { this.state.password === this.state.confirm_password ? (
            this.setState({
                notMatched: false
            })
            ) : (
                this.setState({
                    notMatched: true
                })
            )
        }
    }

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled',
            isDisabledResetEmail: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled',
            isDisabledResetEmail: ''
        })
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            userid: this.state.userid,
            password: this.state.password,
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/ForgetPassword.php', qs.stringify(data), config)
            .then(res => res.data)
            .then(res => {
                // console.log(res)
                if(res.success === 1){
                    this.setState({
                        changed_success: true
                    }, () => {
                        this.endBtnLoader();
                    })
                }
            })
            .catch(error => {
                // console.log(error.response)
            })

        // this.setState({
        //     changed_success: true
        // })
    }
    

    render() {
        // console.log(this.props.match.params.userid);
        // console.log(this.state);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        return (
            <React.Fragment>
                <div className="mb-5">
                    <div className="d-flex justify-content-center">
                        <div className="col-sm-6 col-lg-4">
                            <div className="bg-white p-5 mt-5 shadow-sm rounded-lg">
                                <React.Fragment>
                                    {this.state.changed_success === true ?
                                        (
                                        <div className="text-center">
                                            <img className="m-auto" src={IconLockCheckCircle} alt="success" height="84" />
                                            <h5 className="mt-3">Password updated!</h5>
                                            <p>Password has been changed successfully.</p>
                                            <Link className="btn btn-primary" to='/login'>
                                                Login
                                            </Link>
                                        </div>
                                        ) : (
                                            <form onSubmit={this.handleSubmit}>
                                                <h5 className="mb-5">Set password</h5>
                                                {this.state.notMatched &&
                                                    <div className="alert alert-danger" role="alert">
                                                        New password and confirm password does not match
                                                    </div>
                                                }
                                                <div>
                                                    <div className="form-group">
                                                        <label htmlFor="password">New password <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChange.bind(this)} value={this.state.password} type="password" className="form-control" name="password" placeholder="xxxxxxxxx" required />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="confirm_password">Confirm password <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChange.bind(this)} value={this.state.confirm_password} type="password" className="form-control" name="confirm_password" placeholder="xxxxxxxxx" required />
                                                    </div>

                                                    
                                                </div>
                                                <button className="btn btn-primary w-100" disabled={this.state.isDisabled}>
                                                    {this.state.btn_loader === true ? (<BtnLoader />) : (
                                                        <span>Submit</span>
                                                    )}
                                                </button>
                                            </form>
                                        )
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials
})

export default connect(mapStateToProps, { isLoggedIn })(ForgetPassword);