import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Logo from '../logo.svg';
import IconCog from '../icon/cog.svg';
import IconLogOut from '../icon/sign-out.svg';
import IconBadgePremium from '../icon/badge-premium.svg';
import { connect } from 'react-redux';
import { isLoggedIn, logoutUser, getAvatar, getIsSubscribed } from '../actions/CommonActions';
import { Redirect, Route } from "react-router-dom";

import { 
    IconLifeRingSolidWhite,
    IconBoxSolidWhite
 } from '../icon/index';

class Navbar extends Component {
    state = {
        current: 'mail'
    }
    componentDidMount() {
        this.props.isLoggedIn();
        this.props.getAvatar();
        this.props.getIsSubscribed();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     prevProps.user_credentials !== this.props.user_credentials &&
    //     this.props.getIsSubscribed();
    // }

    handleLogout = (e) => {
        e.preventDefault()
        this.props.logoutUser()
    }
    
    handleClick = e => {
        // console.log('click ', e);
        this.setState({
            current: e.key,
        });
    };
    render() {
        // console.log(this.props)
        // console.log(this.state)

        if (this.props.user_credentials.data !== undefined) {
            var { first_name, last_name } = this.props.user_credentials.data
        }
        if (this.props.avatar !== '') {
            var avatar = this.props.avatar
        }

        this.handleLogout = this.handleLogout.bind(this);
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-white justify-content-between">
                    <div className="container">
                        <NavLink className="navbar-brand" to="/">
                            <img className="img-fluid brand-logo" src={Logo} alt="Resumion" />
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto d-flex align-items-center">
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact={true} to="/">Home <span className="sr-only">(current)</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/pricing">Pricing</NavLink>
                                </li>

                                
                                {this.props.user_credentials.success === 1 ? (
                                    <React.Fragment>
                                        {/* <li className="nav-item">
                                            <NavLink className="nav-link" to="/my-resume">My Resume</NavLink>
                                        </li> */}
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle d-flex align-items-center" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {this.props.avatar !== '' && this.props.avatar !== null ? (
                                                    <>
                                                        {this.props.is_subscribed !== null && this.props.is_subscribed === 1 &&
                                                            <img className="mr-2 position-absolute badge-premium" src={IconBadgePremium} alt="premium" />
                                                        }
                                                        <img className="mr-2 rounded-circle" src={'http://resumion.com/images/users/'+avatar} height="30" alt={first_name} />
                                                    </>
                                                ) : (
                                                    <span className="badge rounded-circle avatar-placeholder d-flex align-items-center mr-2 justify-content-center">{first_name.slice(0, 1) + last_name.slice(0, 1)}</span>
                                                )}
                                                {first_name + " " + last_name}
                                            </Link>
                                            <div className="dropdown-menu bg-primary rounded-lg" aria-labelledby="navbarDropdown">
                                                <NavLink className="dropdown-item text-white rounded-sm mb-2" to="/account-settings"><img className="mr-2" src={IconCog} alt="icon" /> Account Settings</NavLink>
                                                <NavLink className="dropdown-item text-white rounded-sm mb-2" to="/subscription"><img className="mr-2" src={IconBoxSolidWhite} alt="icon" /> Subscription</NavLink>
                                                <div className="dropdown-divider"></div>
                                                <button onClick={this.handleLogout} className="dropdown-item text-white rounded-sm"><img className="mr-2" src={IconLogOut} alt="icon" /> Logout</button>
                                            </div>
                                        </li>
                                    </React.Fragment>
                                ) : (
                                        <React.Fragment>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/builder">Create Account</NavLink>
                                            </li>
                                            <Link className="btn btn-primary ml-3" to="/login">Login</Link>
                                        </React.Fragment>
                                    )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    avatar: state.COM_REDU.avatar,
    is_subscribed: state.COM_REDU.is_subscribed,
    is_subscribed_validity: state.COM_REDU.is_subscribed_validity,
})

export default connect(mapStateToProps, { isLoggedIn, logoutUser, getAvatar, getIsSubscribed })(Navbar);