import React, { Component } from 'react';
import { getResumeidFromSession } from '../CommonFunctions';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import BtnLoader from '../../BtnLoader';
import EmptyState from '../../components/EmptyState'
import { IconHeart, IconTrash, IconPlusCircleWhite, IconTasks } from '../../icon/index';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class CustomArea extends Component {
    state = {
        customareas: null,
        view: true,
        edit: false,
        add_new: false,

        resumeid: null,
        layout_loader: true,
        isDisabled: 'disabled',

        title: '',
        description: '',

        title_edit: null,
        description_edit: null,


        customarea_id_edit: null,
        btn_loader: false
    }


    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.customareas === null && this.props.customareas !== null) {
            this.setState({
                ...this.state,
                customareas: this.props.customareas
            })
            if (prevState.layout_loader === true) {
                this.setState({
                    ...this.state,
                    layout_loader: false
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }

    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMendatoryFields())
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMendatoryFieldsEdit())
    }
    checkMendatoryFields = () => {
        this.state.title.length !== 0 && this.state.description.length !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            })
        )
        
    }

    checkMendatoryFieldsEdit = () => {
        this.state.title_edit.length !== 0 && this.state.description_edit.length !== 0 ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            })
        )
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    handleAdd = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true,
            level: 1
        }, () => this.endBtnLoader())
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false,
            customarea_name: ''
        }, () => this.checkMendatoryFields())
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            title: this.state.title,
            description: this.state.description
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddCustomSection.php', qs.stringify(data), config)
            .then(res => res.data.data[0].customarea[0])
            // .then(res=>console.log(res))
            .then(res => this.setState({
                view: true,
                edit: false,
                add_new: false,
                title: '',
                description: '',
                customareas: [...this.state.customareas, res]
            }), () => this.checkMendatoryFields())
            .then(() => this.notify())
            .then(() => this.endBtnLoader())
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();

        const data = {
            id: this.state.customarea_id_edit,
            title: this.state.title_edit,
            description: this.state.description_edit,
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetCustomSection.php', qs.stringify(data), config)
            .then(res => res.data.data[0].customarea[0])
            // .then(res => console.log(res))
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.customareas.map(customarea => customarea.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let customareas = [...this.state.customareas];
                let customarea = { ...customareas[indices] };
                customarea = res;
                customareas[indices] = customarea;
                this.setState({
                    view: true,
                    edit: false,
                    customareas: customareas
                });
                this.notify();
                this.endBtnLoader();
            })
    }

    handleDelete = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'custom'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    .then(res => console.log(res.data))
                    .then(() => this.notifyDelete())
                const customareas = this.state.customareas.filter(customarea => customarea.id !== id);
                this.setState({ customareas: customareas });
            }
        })
    }

    handleEdit = (id) => {
        // console.log(id)
        const customareas = this.state.customareas.filter(customarea => customarea.id === id);
        this.setState({
            view: false,
            edit: true,
            title_edit: customareas[0].title,
            description_edit: customareas[0].description,
            customarea_id_edit: customareas[0].id,
        }, () => this.checkMendatoryFieldsEdit())
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        // console.log(this.state)
        // console.log(this.props.customareas)

        if (this.state.customareas !== null) {
            var customareas = this.state.customareas.map((customarea, i, arr) => {
                return (
                    <React.Fragment key={customarea.id}>
                    <div className="row">
                        <div className="col-md-10">
                            <h6 className="m-0">{customarea.title}</h6>
                        </div>
                        <div className="ml-auto col-md-2 col-sm-12 text-md-right text-sm-left">
                            <button onClick={this.handleEdit.bind(this, customarea.id)} className="btn btn-link-edit p-0 mr-2"><img src={IconEdit} alt="edit" height="20"/></button>
                            <button onClick={this.handleDelete.bind(this, customarea.id)} className="btn btn-link-delete p-0"><img src={IconTrashSolid} alt="delete" height="20"/></button>
                        </div>
                        <div className="w-100"></div>
                        <div className="col">
                            <p className="m-0 small text-black-3">{customarea.description}</p>
                        </div>
                    </div>
                    {arr.length !== i + 1 &&
                        <hr className="mt-3 mb-3" />
                    }
                    </React.Fragment>
                )
            })
        }

        return (
            <div className="card mb-4">
                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconTasks} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">Custom Areas</h5>
                                        <p className="text-muted mb-0">Anything which does not cover the above criteria but you think you that should appear in your resume, you can add here.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view && this.state.customareas &&
                                    <React.Fragment>
                                        {customareas}
                                        {this.state.customareas !== null && this.state.customareas.length === 0 &&
                                            <div className="col text-center">
                                                <EmptyState />
                                                <button onClick={this.handleAdd} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                            </div>
                                        }
                                        {this.state.customareas !== null && this.state.customareas.length !== 0 &&
                                            <button onClick={this.handleAdd} className="btn btn-primary mt-4"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="title_edit">Title <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="title_edit" placeholder="e.g. Travelling" value={this.state.title_edit} />
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="description_edit">Description <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <textarea row="5" onChange={this.handleChangeEdit} type="text" className="form-control" name="description_edit" placeholder="e.g. Bachelor of Engineering" value={this.state.description_edit}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="title">Title <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="title" placeholder="e.g. Travelling" value={this.state.title} />
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="description">Description <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <textarea row="5" onChange={this.handleChange} type="text" className="form-control" name="description" placeholder="e.g. Bachelor of Engineering" value={this.state.description}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(CustomArea);